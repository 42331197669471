import { createSlice } from "@reduxjs/toolkit";
import filtersModel from "../../../config/main-filters-model";

const initialMatchesFilterState = {
  firstPlayer: null,
  secondPlayersIds: [],
  surfaceTypes: [],
  handedness: null,
};

const initialGeneralFiltersState = {
  player: [],
  winner: [],
  pressure: [],
};

const initialMatchesState = {
  matches: null,
  selectedMatches: [],
  matchesFilter: initialMatchesFilterState,
  filtersModel: filtersModel,
  selectedFilters: [],
  existingFilters: [],
  opponents: null,
  generalFilters: initialGeneralFiltersState,
};

const getSelectedFilters = (filtersModel) => {
  let selectedFilters = [];
  const activeCategory = filtersModel.find((f) => f.isActive === true);
  const activeTab = activeCategory.tabs.find((t) => t.isActive === true);

  const rows = activeTab.filters.filter((r) =>
    r.options.some((o) => o.isSelected === true)
  );
  rows.forEach((r) => {
    let selectedFilter = {
      name: r.name,
      propertyName: r.propertyName,
      type: r.type,
      options: r.options.filter((o) => o.isSelected === true),
    };
    selectedFilters.push(selectedFilter);
  });
  return selectedFilters;
};

const getCountAllTabsSelectedFilters = (filtersModel) => {
  var count = 0;
  filtersModel.forEach((mainFilter) => mainFilter.tabs.forEach(tab => tab.filters.forEach(row => row.options.forEach(o => { if (o.isSelected) count++ }))));
  return count;
}

const matchesSlice = createSlice({
  name: "completeMatches",
  initialState: initialMatchesState,
  reducers: {
    setOpponents(state, action) {
      state.opponents = action.payload;
    },
    setExistingFilters(state, action) {
      state.existingFilters = action.payload;
    },
    setMatches(state, action) {
      state.matches = action.payload;
    },
    setSelectedMatches(state, action) {
      state.selectedMatches = action.payload;
    },
    resetFilters(state) {
      state.selectedFilters = [];
      state.filtersModel = state.filtersModel.map((c) => {
        return {
          ...c,
          tabs: c.tabs.map((t) => {
            return {
              ...t,
              filters: t.filters.map((r) => {
                return {
                  ...r,
                  options: r.options.map((o) => {
                    return {
                      ...o,
                      isSelected: false,
                    };
                  }),
                };
              }),
            };
          }),
        };
      });
    },
    setMatchesFilter(state, action) {
      state.matchesFilter = action.payload;
    },
    setGeneralFilters(state, action) {
      state.generalFilters = action.payload;

      const wonSelected = action.payload.winner.length == 1 && action.payload.winner[0] === 0;
      state.filtersModel = state.filtersModel.map((c) => {
        return {
          ...c,
          isDisabled: c.name == "Rally Errors" ? wonSelected : c.isDisabled
        }
      });

      const currentActiveTab = state.filtersModel.find((c) => c.isActive === true).name;

      if (wonSelected && currentActiveTab == "Rally Errors") {
        state.filtersModel = state.filtersModel.map((c) => {
          return {
            ...c,
            isActive: c.name == "Serve" ? true : false,
            tabs: c.tabs.map((t) => {
              return {
                ...t,
                isActive: t.name == "In" ? true : false,
              };
            }),
          };
        });
      }
    },

    toggleFilter(state, action) {
      const newFiltersModel = state.filtersModel.map((c) => {
        if (c.name === action.payload.category) {
          return {
            ...c,
            tabs: c.tabs.map((t) => {
              if (t.name === action.payload.tab) {
                return {
                  ...t,
                  filters: t.filters.map((r) => {
                    if (r.name === action.payload.row) {
                      return {
                        ...r,
                        options: r.options.map((o) => {
                          if (o.name === action.payload.option) {
                            return {
                              ...o,
                              isSelected: !o.isSelected,
                            };
                          }
                          return o;
                        }),
                      };
                    }
                    return r;
                  }),
                };
              }
              return t;
            }),
          };
        }
        return c;
      });

      state.filtersModel = newFiltersModel;
      state.selectedFilters = getSelectedFilters(newFiltersModel);
    },

    activateTab(state, action) {
      const newFiltersModel = state.filtersModel.map((c) => {
        if (c.name === action.payload.category) {
          return {
            ...c,
            isActive: true,
            tabs: c.tabs.map((t) => {
              if (t.name === action.payload.tab) {
                return {
                  ...t,
                  isActive: true,
                };
              } else {
                return {
                  ...t,
                  isActive: false,
                };
              }
            }),
          };
        } else {
          return {
            ...c,
            isActive: false,
            tabs: c.tabs.map((t) => {
              return {
                ...t,
                isActive: false,
              };
            }),
          };
        }
      });
      state.filtersModel = newFiltersModel;
      state.selectedFilters = getSelectedFilters(newFiltersModel);
    },
  },
});

export const {
  setSelectedMatches,
  resetFilters,
  toggleFilter,
  setMatchesFilter,
  setMatches,
  activateTab,
  setGeneralFilters,
  setExistingFilters,
  setOpponents
} = matchesSlice.actions;

export const selectSelectedMatches = (state) => state.completeMatches.selectedMatches;

export const selectFiltersModel = (state) => state.completeMatches.filtersModel;

export const selectSelectedFilters = (state) => state.completeMatches.selectedFilters;

export const selectMatchesFilter = (state) => state.completeMatches.matchesFilter;

export const selectMatches = (state) => state.completeMatches.matches;

export const selectActiveMainFilter = (state) => state.completeMatches.filtersModel.find((c) => c.isActive === true);

export const selectActiveTab = (state) => state.completeMatches.filtersModel.find((c) => c.isActive === true).tabs.find((t) => t.isActive === true);

export const selectGeneralFilters = (state) => state.completeMatches.generalFilters;

export const selectExistingFilters = (state) => state.completeMatches.existingFilters;

export const selectOpponents = (state) => state.completeMatches.opponents;

export const selectCountAllTabsSelectedFilters = (state) => getCountAllTabsSelectedFilters(state.completeMatches.filtersModel);

export default matchesSlice.reducer;
