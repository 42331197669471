import React from "react";
import {
  selectFiltersModel,
  selectActiveMainFilter,
  activateTab,
} from "../store/matchesSlice";
import { useDispatch, useSelector } from "react-redux";
import { textToPascalCase } from "../../../utils/app-utils";

export default function MainFilters() {
  const dispatch = useDispatch();
  const filtersModel = useSelector(selectFiltersModel);
  const activeMainFilter = useSelector(selectActiveMainFilter);
  return (
    <div className="card mb-3">
      <div className="card-header d-flex justify-content-between">
        <h5>Filters</h5>
      </div>
      <div className="card-body p-0 bg-light">
        <div className="list-group">
          {filtersModel.map((filter) => {
            let classes = "list-group-item list-group-item-action";
            if (filter.name === activeMainFilter.name) {
              classes += " bg-500 text-white";
            }
            if (filter.isDisabled) {
              classes += " disabled";
            }
            return (
              <a
                style={{ cursor: "pointer" }}
                key={filter.name}
                className={classes}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  dispatch(
                    activateTab({
                      category: filter.name,
                      tab: filter.tabs[0].name,
                    })
                  );
                }}
              >
                <strong>{textToPascalCase(filter.name)}</strong>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}
