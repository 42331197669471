import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentlyPlayingPoint,
  selectIsPlaying,
  selectNavigateToPoint,
  selectSelectedPointsIds,
  setSelectedPointsIds,
  setNavigateToPoint
} from "../store/videoPlayerSlice";
import { setCurrentlyPlayingPoint } from "../store/videoPlayerSlice";
import { secondsToTime } from "../../../utils/app-utils";
import { setIsPlaying } from "../store/videoPlayerSlice";
import ShotItem from "./ShotItem";
import classes from "../CompleteMatchesPage.module.css";
import pointSelectionClasses from "./PointSelection.module.css";
import { useEffect, useRef } from "react";

const PointItem = ({ point }) => {
  const dispatch = useDispatch();

  const currentlyPlayingPoint = useSelector(selectCurrentlyPlayingPoint);
  const selectedPointsIds = useSelector(selectSelectedPointsIds);
  const isPlaying = useSelector(selectIsPlaying);
  const navigateToPoint = useSelector(selectNavigateToPoint);
  const pointRef = useRef(null);

  const isSelected = selectedPointsIds?.includes(point.pointId);

  useEffect(() => {
    if (
      navigateToPoint &&
      currentlyPlayingPoint?.pointId === point.pointId &&
      !pointRef.current.isIntersecting
    ) {
      pointRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      dispatch(setNavigateToPoint(false));
    }
  }, [navigateToPoint]);

  const pointSelectionHandler = (e) => {
    if (e.target.checked) {
      dispatch(setSelectedPointsIds([point.pointId, ...selectedPointsIds]));
    } else {
      dispatch(setSelectedPointsIds(selectedPointsIds.filter((id) => id !== point.pointId)));
    }
  }
  let actionButton = null;
  if (isPlaying && currentlyPlayingPoint?.pointId === point.pointId) {
    actionButton = (
      <div
        key={`pause-${point.pointId}`}
        style={{ cursor: "pointer", marginRight: "1rem", marginLeft: "auto" }}
        onClick={(e) => {
          dispatch(setIsPlaying(false));
        }}
      >
        <span className="fas fa-pause"></span>
      </div>
    );
  } else {
    actionButton = (
      <div
        key={`play-${point.pointId}`}
        style={{ cursor: "pointer", marginRight: "1rem", marginLeft: "auto" }}
        onClick={(e) => {
          dispatch(setCurrentlyPlayingPoint(point.pointId));
        }}
      >
        <span className="fas fa-play"></span>
      </div>
    );
  }

  let pointserve = point.serveTypeId === 0 ? "1st" : point.serveTypeId === 1 ? "2nd" : "Double Fault";
  let pointserveColor = point.serveTypeId === 0 ? "badge-soft-info" : point.serveTypeId === 1 ? "badge-soft-primary" : "badge-soft-danger";
  let court = point.serviceCourt === 0 ? "Ad" : "Deuce";
  let courtColor = point.serviceCourt === 0 ? "badge-soft-primary" : "badge-soft-success";

  return (
    <div
      ref={pointRef}
      className={
        "list-group-item list-group-item-action flex-column align-items-start " +
        (currentlyPlayingPoint?.pointId === point.pointId ? "bg-200 text-white" : "")
      }
    >
      <div className="row align-items-center">
        <div className="custom-control custom-checkbox">
          <input id={`checkbox-${point.pointId}`} className="custom-control-input" type="checkbox" checked={isSelected} onChange={pointSelectionHandler} />
          <label className={`custom-control-label ${classes["match-list-item-label-text"]}`} htmlFor={`checkbox-${point.pointId}`}></label>
        </div>
        <label className={`${classes["match-list-item-label-text"]} mb-0`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            dispatch(setCurrentlyPlayingPoint(point.pointId));
          }} >
          <span className="match-list-item-label-text">
            {secondsToTime(point.startTime) + " - " + point.matchScore + " - " + point.setScore + " - " + point.gameScore}
          </span>
        </label>
        {actionButton}
      </div>
      <div className="row mt-2">
        <span className={"badge rounded-capsule match-capsule-indicator ml-1 " + courtColor}>{court}</span>
        <span className={"badge rounded-capsule match-capsule-indicator ml-1 " + pointserveColor} >{pointserve}</span>
        <span className="badge badge-soft-light rounded-capsule match-capsule-indicator ml-1">Duration: {point.endTime - point.startTime} sec</span>
        {(point.isAce || point.isPressure || point.isBreak) && (
          <div>
            {point.isAce ? (
              <span className="badge badge-soft-warning rounded-capsule match-capsule-indicator ml-1">
                Ace
              </span>
            ) : null}
            {point.isBreak ? (
              <span className="badge badge-soft-warning rounded-capsule match-capsule-indicator ml-1">
                Break
              </span>
            ) : null}
            {point.isPressure ? (
              <span className="badge badge-soft-warning rounded-capsule match-capsule-indicator ml-1">
                Pressure
              </span>
            ) : null}
          </div>
        )}
      </div>
      <div
        className={`row mt-2 align-items-center ${pointSelectionClasses["point-shots-container"]}`}
      >
        {point.shots.map((shot) => (
          <ShotItem key={shot.shotId} shot={shot} />
        ))}
      </div>
    </div>
  );
};

export default PointItem;
