const ConfirmationModal = ({ action, title, body, onConfirm, disabled }) => {
    return (
        <div
            className="modal fade"
            id={`${action}ConfirmationModal`}
            role="dialog"
            aria-labelledby={`${action}ConfirmationModaLabel`}
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id={`${action}ConfirmationModaLabel`}>
                            {title}
                        </h3>
                        <button
                            className="close"
                            type="button"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span className="font-weight-light" aria-hidden="true">
                                &times;
                            </span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p className="mb-2">
                            {body}
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary btn-md"
                            type="button"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                        <button
                            disabled={disabled}
                            className="btn btn-warning btn-md"
                            type="button"
                            onClick={onConfirm}
                        >Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
