import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectSelectedFilters,
  toggleFilter,
  selectActiveTab,
  selectActiveMainFilter,
  selectCountAllTabsSelectedFilters,
  resetFilters,
} from "../store/matchesSlice";

export default function SelectedFilters() {
  const dispatch = useDispatch();
  const selectedFilters = useSelector(selectSelectedFilters);
  const activeMainFilter = useSelector(selectActiveMainFilter);
  const activeTab = useSelector(selectActiveTab);
  const countAllTabsSelectedFilters = useSelector(selectCountAllTabsSelectedFilters);

  const countCurrentTabSelectedFilters = selectedFilters.reduce((total, filter) => {
    return total + filter.options.length;
  }, 0);

  return (
    <div className="card">
      <div className="card-header d-flex flex-wrap justify-content-between align-items-center">
        <h5>Selected Filters</h5>
        <div className="d-flex flex-wrap align-items-center">
          <button
            className="btn btn-falcon-default btn-sm mr-2"
            onClick={(e) => {
              selectedFilters.forEach((filter) => {
                filter.options.forEach((option) => {
                  dispatch(
                    toggleFilter({
                      category: activeMainFilter.name,
                      tab: activeTab.name,
                      row: filter.name,
                      option: option.name,
                    })
                  );
                });
              });
            }}
          >
            {`Clear Tab (${countCurrentTabSelectedFilters})`}
          </button>

          <button
            className="btn btn-falcon-default btn-sm"
            onClick={() => {
              dispatch(resetFilters());
            }}
          >
            {`Clear All (${countAllTabsSelectedFilters})`}
          </button>
        </div>
      </div>
      <div className="card-body py-2 px-1 d-flex flex-wrap scrollbar perfect-scrollbar bg-light">
        {selectedFilters.length === 0 ? (
          <strong>No Filters are selected</strong>
        ) : (
          selectedFilters.map((filter) => {
            return filter.options.map((option, index) => {
              return (
                <div className="d-flex align-items-center badge badge-soft-warning m-1 fs--2" key={index}>
                  <div
                    className="text-dark mr-2 fs--1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(
                        toggleFilter({
                          category: activeMainFilter.name,
                          tab: activeTab.name,
                          row: filter.name,
                          option: option.name,
                        })
                      );
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </div>
                  <div className="text-nowrap text-left">
                    {filter.name.toUpperCase()}: {option.name}
                  </div>
                </div>
              );
            });
          })
        )}
      </div>
    </div>
  );
}
