import Matches from "./MatchesList/Matches";
import PointSelection from "./PointSelection/PointSelection";
import VideoPlayer from "./VideoPlayer/VideoPlayer";
import ShotsCount from "./VideoPlayer/ShotsCount";
import SelectedFilters from "./Filters/SelectedFilters";
import GeneralFilters from "./Filters/GeneralFilters";
import Filters from "./Filters/Filters";
import { useSelector } from "react-redux";
import { selectIsVideoClient, selectUser, selectIsAllowedShotsPlayer } from "../auth/authSlice";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import Spinner from "../../UI/Spinner";
import MainFilters from "./Filters/MainFilters";

const CompleteMatchesPage = () => {
  const isVideoClient = useSelector(selectIsVideoClient);
  const user = useSelector(selectUser);
  const isAllowedShotsView = useSelector(selectIsAllowedShotsPlayer);


  if (!user || isAllowedShotsView == null) return <Spinner />;
  else if (!isVideoClient || isAllowedShotsView === false) return <NotAuthorizedPage />;

  return (
    <div className="row">
      <div className="col-xl-2 mb-3 pr-0">
        <GeneralFilters />
        <MainFilters />
        <SelectedFilters />
      </div>
      <div className="col-xl-7 mb-3 pr-0">
        <Matches />
        <VideoPlayer />
        <div className="row">
          <div className="col-xl-12">
            <Filters />
          </div>
        </div>
      </div>
      <div className="col-xl-3 mb-3 pr-0">
        <PointSelection />
        <ShotsCount />
      </div>
    </div>
  );
};

export default CompleteMatchesPage;
