


const ReportPreviewPage = () => {
    const containerStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        padding: 0,
        height: '100%',
        width: '100%',
    };

    const iframeStyle = {
        width: '100%',
        height: '100%',
        border: 'none',
    };

    //update browser title to match report title
    document.title = "Dart (2022-2024, All, 21 Opponents) Scouting Report With Groundstrokes";

    const path = "https://operationslakedb.blob.core.windows.net/gsa-operations/report-automation/0bc78675-b49d-4593-be22-702244f02e19/standard-report/69022798-ed10-4b3e-911e-f74fe4e0591b/Dart%20(2022-2024,%20All,%2021%20Opponents)%20Scouting%20Report%20With%20Groundstrokes.pdf?sv=2023-11-03&spr=https%2Chttp&st=2024-07-26T04%3A22%3A29Z&se=2027-01-02T05%3A22%3A00Z&sr=b&sp=r&sig=AtFvRuCaB8txOSJ99swYkrlmumw0O%2BZdTVGZU66kT%2FM%3D"
    return (
        <div style={containerStyle}>
            <iframe src={path} style={iframeStyle} title="iframe-content" allowFullScreen></iframe>
        </div>
    );
};


export default ReportPreviewPage;