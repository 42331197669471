import { createSlice } from "@reduxjs/toolkit";
import { backhandTypeOptions, surfaceOptions } from "../constants/reportGenerationOptions";

const initialReportGenerationInputs = {
    stdReportInputs: {
        category: null,
        selectedTargetPlayers: [],
        selectedOpponentPlayers: [],
        selectedTournaments: [],
        opponent: 0,
        fromYear: 2024,
        toYear: 2024,
        targetSpeedUnit: "KMH",
        isHardSelected: true,
        isClaySelected: true,
        isGrassSelected: true,
        isCarpetSelected: true,
        withRets: false,
        backhandType: backhandTypeOptions[0].value,
        useHEData: 2,
        gameResultSelection: 2,
        gameServeSelection: 2,
        includeTieBreakGames: true,
    },
    bgReportInputs: null,
    selectedSurfaces: [surfaceOptions[0]]
};

const reportGenerationSlice = createSlice({
    name: "reportGeneration",
    initialState: initialReportGenerationInputs,
    reducers: {
        setStdReportInputs(state, action) {
            state.stdReportInputs = action.payload;
        },
        setBgReportInputs(state, action) {
            state.bgReportInputs = action.payload;
        },
        setSelectedSurfaces(state, action) {
            state.selectedSurfaces = action.payload;
        }
    },
});

export const {
    setStdReportInputs,
    setBgReportInputs,
    setSelectedSurfaces
} = reportGenerationSlice.actions;

export const selectStdReportInputs = (state) => state.reportGeneration.stdReportInputs;
export const selectBgReportInputs = (state) => state.reportGeneration.bgReportInputs;
export const selectSelectedSurfaces = (state) => state.reportGeneration.selectedSurfaces;
export default reportGenerationSlice.reducer;
