import classes from "../ReportGenerationPage.module.css"

const TrackingSheet = ({ trackingSheet, targetLocalName }) => {
    const getHandednessClass = (handedness) => {
        const classes = {
            "R": "badge-dark",
            "L": "bg-300 text-white",
            "1H": "badge-warning",
            "2H": "badge-primary"
        };
        return classes[handedness] || "";
    }

    const getBadge = (handedness, type) => (
        <span className={`badge badge-sm rounded-capsule mr-1 fs--2 ${getHandednessClass(handedness)}`}>
            {handedness === "R" ? "Righty" : handedness === "L" ? "Lefty" : handedness === "1H" ? "1H BH" : handedness === "2H" ? "2H BH" : "- BH"}
        </span>
    );

    const getOpponentData = (match) => {
        const isPlayer1 = match.localNamePlayer1 === targetLocalName;
        const opponentName = isPlayer1 ? match.localNamePlayer2 : match.localNamePlayer1;
        const handedness = isPlayer1 ? match.handednessPlayer2 : match.handednessPlayer1;
        const backhand = isPlayer1 ? match.backhandPlayer2 : match.backhandPlayer1;

        return (
            <>
                <td>{opponentName}</td>
                <td>
                    {getBadge(handedness)}
                    {getBadge(backhand)}
                </td>
            </>
        );
    }

    return (
        <div style={{ marginRight: "-15px", marginLeft: "-15px" }} className="container-fluid d-flex flex-column align-items-center">
            <br />
            <div className="col-4 alert alert-secondary text-center font-weight-bold lead mb-2" role="alert">
                {trackingSheet.matches.length} Matches
            </div>
            <br />
            <div style={{ width: "100%" }}>
                <table className={`table table-striped table-bordered table-hover table-sm mb-4 ${classes['table-auto']}`}>
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Year</th>
                            <th scope="col">Tournament</th>
                            <th scope="col">Opponent</th>
                            <th scope="col">Handedness</th>
                            <th scope="col">Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {trackingSheet.matches.map((match, index) => (
                            <tr className="text-align-center" key={`match-${index}`}>
                                <td>{index + 1}</td>
                                <td>{match.year}</td>
                                <td>{match.eventName}</td>
                                {getOpponentData(match)}
                                <td>{match.result}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TrackingSheet;
