import Select from "react-select";
const MatchesFilters = ({ players, opponents, matchesFilter, setSnapshotMatchesFilter }) => {
  const surfaceOptions = [
    { value: 0, label: "Clay" },
    { value: 1, label: "Grass" },
    { value: 2, label: "Carpet" },
    { value: 3, label: "Hard Court" },
    { value: 4, label: "Acrylic" },
  ];

  const handednessOptions = [
    { value: null, label: "All" },
    { value: 0, label: "Right" },
    { value: 1, label: "Left" },
  ];

  const playerOptions = players.map((player) => ({
    value: player.playerId,
    label: `${player.name}`,
  }));

  let opponentOptions = [];

  if (matchesFilter.firstPlayer && opponents) {
    opponentOptions = opponents.map((opponent) => ({
      value: opponent.playerId,
      label: `${opponent.name}`,
    }));
  }

  const selectedPlayerOption = playerOptions.find((player) => matchesFilter.firstPlayer?.playerId === player.value);

  const selectedOpponentsOptions = opponentOptions.filter((opponent) =>
    matchesFilter.secondPlayersIds?.some((id) => id === opponent.value)
  );
  const selectedSurfacesOptions = surfaceOptions.filter((surface) =>
    matchesFilter.surfaceTypes?.some((id) => id === surface.value)
  );
  const selectedHandednessOption = handednessOptions.find((option) => option.value === matchesFilter.handedness);

  const handleSelectPlayerChange = (selectedOption) => {
    const player = players.find((p) => p.playerId === selectedOption.value);
    setSnapshotMatchesFilter(
      {
        ...matchesFilter,
        secondPlayersIds: [],
        firstPlayer: {
          playerName: player.name,
          playerId: player.playerId,
          category: player.category,
        },
      },
      true
    );
  };

  const handleSelectOpponentChange = (selectedOptions) => {
    let selectedOpponentIds = selectedOptions.map((option) => option.value);
    setSnapshotMatchesFilter({
      ...matchesFilter,
      secondPlayersIds: selectedOpponentIds,
    });
  };

  const handleSelectSurfaceChange = (selectedOptions) => {
    let selectedSurfaceIds = selectedOptions.map((option) => option.value);
    setSnapshotMatchesFilter({
      ...matchesFilter,
      surfaceTypes: selectedSurfaceIds,
    });
  };

  const handleSelectHandednessChange = (selectedOption) => {
    setSnapshotMatchesFilter({
      ...matchesFilter,
      handedness: selectedOption.value,
    });
  };

  return (
    <div className="container d-flex flex-wrap mb-4 justify-content-center">
      <div className="col-xl-3 col-md-6 mb-3">
        <h6 className="text-nowrap fs-0"> First Player</h6>
        <Select
          closeMenuOnSelect={true}
          styles={customStyles}
          options={playerOptions}
          onChange={handleSelectPlayerChange}
          value={selectedPlayerOption ?? ""}
          filterOption={(option, rawInput) => {
            const words = option.label.toLowerCase().split(" ");
            let result = words.some((word) => word.startsWith(rawInput));
            return result;
          }}
        />
      </div>

      <div className="col-xl-3 col-md-6 mb-3">
        <h6 className="text-nowrap fs-0"> Second Player</h6>
        <Select
          closeMenuOnSelect={true}
          styles={customStyles}
          isMulti
          options={opponentOptions}
          onChange={handleSelectOpponentChange}
          placeholder="Select"
          value={selectedOpponentsOptions}
        />
      </div>

      <div className="col-xl-3 col-md-6 mb-3">
        <h6 className="text-nowrap fs-0"> Surface</h6>
        <Select
          closeMenuOnSelect={false}
          styles={customStyles}
          isMulti
          options={surfaceOptions}
          onChange={handleSelectSurfaceChange}
          placeholder="Select"
          value={selectedSurfacesOptions}
        />
      </div>

      <div className="col-xl-3 col-md-6 mb-3">
        <h6 className="text-nowrap fs-0">Opponent Handedness</h6>
        <Select
          closeMenuOnSelect={true}
          styles={customStyles}
          options={handednessOptions}
          onChange={handleSelectHandednessChange}
          value={selectedHandednessOption}
        />
      </div>
    </div>
  );
};

const customStyles = {
  option: (provided) => ({
    ...provided,
    color: "black",
  }),
  control: (provided) => ({
    ...provided,
    color: "black",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "black",
  }),
};

export default MatchesFilters;
