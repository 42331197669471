import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const completeMatchesSlice = createApi({
  reducerPath: "completedMatchesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    mode: "cors",
    prepareHeaders: (headers) => {
      const token = getAuthToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "completedPlayers",
    "completedMatches",
    "completedMatchesPoints",
    "customClips"
  ],
  endpoints: (builder) => ({
    getPlayers: builder.query({
      query: () => "/players/complete/userplayers/shotsview",
      providesTags: (result, error, args) => ["completedPlayers"],
      extraOptions: { maxRetries: 3 },
    }),
    getUserPlayers: builder.query({
      query: () => "/players/complete/userplayers",
      providesTags: (result, error, args) => ["completedPlayers"],
      extraOptions: { maxRetries: 3 },
    }),
    getAllLocalPlayers: builder.query({
      query: () => "/players/all/local",
      extraOptions: { maxRetries: 3 },
    }),
    getEventInstances: builder.query({
      query: () => "/events/all",
      extraOptions: { maxRetries: 3 },
    }),
    getTargetEventInstances: builder.mutation({
      query: (payload) => ({
        url: "/events/local/target",
        method: "POST",
        body: payload,
      }),
      extraOptions: { maxRetries: 3 },
    }),
    getAllCompleteMatches: builder.query({
      query: () => "/matches/complete/qa",
      providesTags: (result, error, args) => [
        "completedMatches",
      ],
      extraOptions: { maxRetries: 3 },
    }),
    getFilteredMatches: builder.mutation({
      query: (matchFilter) => ({
        url: `/matches/complete`,
        method: "POST",
        body: matchFilter,
      }),
      providesTags: (result, error, args) => [
        "completedMatches",
        args.matchFilter,
      ],
    }),
    createCustomClip: builder.mutation({
      query: (customClip) => ({
        url: `/videos/custom`,
        method: "POST",
        body: customClip,
      }),
      providesTags: (result, error, args) => [
        "customClips",
        { type: "customClips", customClip: args.customClip },
      ],
      invalidatesTags: (result, error, args) => ["customClips"],
    }),
    updateHEPoint: builder.mutation({
      query: (points) => ({
        url: `/matches/he/edit`,
        method: "POST",
        body: points,
      })
    }),
    setMatchInstanceStatusToQA: builder.mutation({
      query: (matchInstanceId) => ({
        url: `/matches/set/status/qa?matchInstanceId=${matchInstanceId}`,
        method: "POST"
      }),
    }),
    setMatchInstanceStatusToReady: builder.mutation({
      query: (matchInstanceId) => ({
        url: `/matches/set/status/ready?matchInstanceId=${matchInstanceId}`,
        method: "POST"
      }),
    }),
    deleteCustomClip: builder.mutation({
      query: (clipName) => ({
        url: `/videos/custom?clipName=${clipName}`,
        method: "DELETE",
      }),
      providesTags: (result, error, args) => [
        "customClips",
        { type: "customClips", clipName: args.clipName },
      ],
      invalidatesTags: (result, error, args) => ["customClips"],
    }),
    checkCustomClipExists: builder.query({
      query: (clipName) => `/videos/custom/check?clipName=${clipName}`,
      providesTags: (result, error, args) => [
        "customClips",
        { type: "customClips", clipName: args.clipName },
      ],
    }),
    getCustomClips: builder.query({
      query: () => `/videos/custom`,
      providesTags: (result, error, args) => ["customClips"],
    }),
    loadCompletedMatchPoints: builder.query({
      query: (selectedMatches) => {
        return {
          url: `/matches/complete/points/`,
          params: { selectedMatches },
        };
      },
      transformResponse: (response) => {
        //response is dict with key as matchInstanceId and value as pointList
        for (let key in response) {
          let pointList = response[key];
          for (let i = 0; i < pointList.length; i++) {
            pointList[i].isFiltered = false;
            for (let j = 0; j < pointList[i].shots.length; j++) {
              pointList[i].shots[j].isFiltered = false;
            }
          }
        }
        return response;
      },
      providesTags: (result, error, args) => [
        "completedMatchesPoints",
        args.selectedMatches ?? "",
      ],
    }),
    searchMatches: builder.query({
      query: ({ p1Id, p2Id, eventInstanceId }) => {
        if (p1Id === undefined || p2Id === undefined || eventInstanceId === undefined) {
          return {
            url: `/matches/search?p1Id=0&p2Id=0&eventInstanceId=0`,
          };
        }
        return {
          url: `/matches/search?p1Id=${p1Id}&p2Id=${p2Id}&eventInstanceId=${eventInstanceId}`,
        };
      },
      refetchOnMountOrArgChange: true,
    }),
    addNewMatch: builder.mutation({
      query: (match) => ({
        url: `/matches/add`,
        method: "POST",
        body: match,
      }),
    })
  }),
});

export const {
  useGetPlayersQuery,
  useGetUserPlayersQuery,
  useGetAllLocalPlayersQuery,
  useGetEventInstancesQuery,
  useGetTargetEventInstancesMutation,
  useLoadCompletedMatchPointsQuery,
  useGetCustomClipsQuery,
  useLazyCheckCustomClipExistsQuery,
  useGetAllCompleteMatchesQuery,
  useGetFilteredMatchesMutation,
  useCreateCustomClipMutation,
  useDeleteCustomClipMutation,
  useSearchMatchesQuery,
  useUpdateHEPointMutation,
  useSetMatchInstanceStatusToQAMutation,
  useSetMatchInstanceStatusToReadyMutation,
  useAddNewMatchMutation
} = completeMatchesSlice;
