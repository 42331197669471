import { useDispatch, useSelector } from "react-redux";
import { selectGeneralFilters, setGeneralFilters } from "../store/matchesSlice";
import { selectMatchesFilter } from "../store/matchesSlice";
import ButtonGroup from "../UI/ButtonGroup";

const GeneralFilters = () => {
  const dispatch = useDispatch();
  const matchesFilter = useSelector(selectMatchesFilter);
  const generalFilters = useSelector(selectGeneralFilters);

  const playerOptions = [
    {
      displayName: matchesFilter.firstPlayer?.playerName ?? "Player",
      value: matchesFilter.firstPlayer?.playerId,
    },
    {
      displayName: "Opponents",
      value: -1,
    },
  ];

  const winnerOptions = [
    {
      displayName: "Won",
      value: 0,
    },
    {
      displayName: "Lost",
      value: 1,
    },
  ];

  const pressureOptions = [
    {
      displayName: "Non-Pressure",
      value: 0,
    },
    {
      displayName: "Pressure",
      value: 1,
    },
    {
      displayName: "Break",
      value: 2,
    },
  ];

  const selectPlayerHandler = (options) => {
    if (options.length === 0 || options.length == 2)
      dispatch(
        setGeneralFilters({
          ...generalFilters,
          player: options,
          winner: [],
        })
      );
    else dispatch(setGeneralFilters({ ...generalFilters, player: options }));
  };
  return (
    <div className="card mb-3">
      <div className="card-header">
        <h5>General Filters</h5>
      </div>
      <div className="card-body p-0 bg-light">
        <ButtonGroup
          options={playerOptions}
          field="player"
          selectedOptions={generalFilters.player}
          setSelectedOptions={(options) => selectPlayerHandler(options)}
          isDisabled={matchesFilter.firstPlayer?.playerId === undefined}
        />
        <ButtonGroup
          options={winnerOptions}
          field="winner"
          selectedOptions={generalFilters.winner}
          setSelectedOptions={(options) =>
            dispatch(setGeneralFilters({ ...generalFilters, winner: options }))
          }
          isDisabled={
            matchesFilter.firstPlayer?.playerId === undefined ||
            generalFilters.player.length === 0 ||
            generalFilters.player.length === 2
          }
        />
        <ButtonGroup
          options={pressureOptions}
          field="pressure"
          selectedOptions={generalFilters.pressure}
          setSelectedOptions={(options) =>
            dispatch(
              setGeneralFilters({ ...generalFilters, pressure: options })
            )
          }
          isDisabled={false}
        />
      </div>
    </div>
  );
};

export default GeneralFilters;
