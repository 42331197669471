import { useEffect, useRef } from "react";
import { useReportIssueMutation } from "../api/reportsSlice";

const ReportIssue = ({ reportId }) => {
    const [reportIssue, { isSuccess: reportIssueSuccessful, isError: reportIssueFailed, isLoading: reportingIssue }] = useReportIssueMutation();
    const commentRef = useRef(null);

    useEffect(() => {
        toastr.options.positionClass = "toast-bottom-right";
        if (reportIssueSuccessful) {
            toastr.success("Issue reported successfully!", "Report Issue");
            commentRef.current.value = "";
            $("#reportIssueConfirmationModal").modal("hide");
        }
        if (reportIssueFailed) {
            toastr.error("Failed to report issue!", "Report Issue");
        }
    }, [reportIssueSuccessful, reportIssueFailed]);

    return (
        <div
            className="modal fade"
            id="reportIssueConfirmationModal"
            role="dialog"
            aria-labelledby="reportIssueConfirmationModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="reportIssueConfirmationModalLabel">
                            Report Issue
                        </h3>
                        <button
                            className="close"
                            type="button"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span className="font-weight-light" aria-hidden="true">
                                &times;
                            </span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p className="mb-2">
                            Do you want to report an issue with this generation of this report?
                        </p>
                        <textarea
                            className="form-control"
                            ref={commentRef}
                            placeholder="Enter your comment here..."
                        ></textarea>

                    </div>
                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary btn-md"
                            type="button"
                            data-dismiss="modal"
                            onClick={() => commentRef.current.value = ""}
                        >
                            Cancel
                        </button>
                        <button
                            disabled={reportingIssue}
                            className="btn btn-warning btn-md"
                            type="button"
                            onClick={() => { reportIssue({ clientReportId: reportId, comment: commentRef.current.value }); }}
                        > {reportingIssue ? "Reporting Issue" : "Confirm"}</button>
                    </div>
                </div>
            </div>
        </div >
    );
}
export default ReportIssue;