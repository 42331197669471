import React, { useEffect, useState } from "react";
import classes from "./MatchesList.module.css";
import { formatUtcDate } from "../../../utils/app-utils";
import { useSelector } from "react-redux";
import { selectIsAdmin } from "../../auth/authSlice";
import { useSetMatchInstanceStatusToQAMutation } from "../../api/completeMatchesSlice";

const MatchesList = ({ matches, onToggleMatch, matchesMultipleSelection }) => {
  const isAdmin = useSelector(selectIsAdmin);
  const [setMatchInstanceStatusToQA, { isSuccess: setToQaSuccess, isError: setToQaError }] =
    useSetMatchInstanceStatusToQAMutation();

  const [selectedMatch, setSelectedMatch] = useState(null);

  useEffect(() => {
    if (setToQaSuccess) {
      toastr.success(
        `${selectedMatch.firstPlayerName} vs ${selectedMatch.secondPlayerName} match sent to be reviewed`,
        "Success"
      );
    } else if (setToQaError) {
      toastr.error(
        `${selectedMatch.firstPlayerName} vs ${selectedMatch.secondPlayerName} failed to flagged for review`,
        "Error"
      );
    }
  }, [setToQaSuccess, setToQaError]);

  if (!matches || matches.length === 0) {
    return (
      <div className="d-flex justify-content-center">
        <h4>No matches are available.</h4>
      </div>
    );
  }
  const allMatchesSelected = matches.every((match) => match.isSelected);
  return (
    <div className={`d-flex justify-content-center ${classes["table-container"]}`}>
      <table
        className={`table table-sm table-dark table-responsive scrollbar perfect-scrollbar ${classes["matches-table"]}`}
      >
        <caption>Matches List</caption>
        <thead>
          <tr>
            <th scope="col">
              <input
                type="checkbox"
                checked={allMatchesSelected}
                onChange={() => matchesMultipleSelection(!allMatchesSelected)}
              />
            </th>
            <th scope="col" className="text-nowrap">
              First Player
            </th>
            <th scope="col" className="text-nowrap">
              Second Player
            </th>
            <th scope="col" className="text-nowrap">
              Event
            </th>
            <th scope="col" className="text-nowrap">
              Round
            </th>
            <th scope="col" className="text-nowrap">
              Surface
            </th>
            <th scope="col" className="text-nowrap">
              Winner
            </th>
            <th scope="col" className="text-nowrap">
              Date
            </th>
            {isAdmin && (
              <th scope="col" className="text-nowrap">
                Actions
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {matches.map((match) => (
            <tr key={match.matchId}>
              <th scope="row">
                <input type="checkbox" checked={match.isSelected} onChange={() => onToggleMatch(match)} />
              </th>
              <td className="text-nowrap">{match.firstPlayerName}</td>
              <td className="text-nowrap">{match.secondPlayerName}</td>
              <td className="text-nowrap">{match.eventName}</td>
              <td className="text-nowrap">{match.roundName}</td>
              <td className="text-nowrap">{match.surface}</td>
              <td className="text-nowrap">{match.winnerName}</td>
              <td className="text-nowrap">{formatUtcDate(match.matchDate)}</td>
              {isAdmin && (
                <td>
                  <button
                    className="btn btn-outline-warning btn-sm mx-1 text-nowrap py-1"
                    type="button"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Edit"
                    onClick={() => {
                      setSelectedMatch(match);
                      setMatchInstanceStatusToQA(match.matchInstanceId);
                    }}
                  >
                    Needs Review
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MatchesList;
