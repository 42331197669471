
const newFiltersModel = [
    {
        name: 'Serve',
        isActive: true,
        isDisabled: false,
        tabs: [
            {
                name: 'In',
                level: 'shot',
                isActive: true,
                isDisabled: false,
                filters: [
                    {
                        name: 'SERVE NUMBER',
                        type: 'advanced',
                        options: [
                            {
                                name: '1ST',
                                isSelected: false,

                            },
                            {
                                name: '2ND',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'SERVE DIRECTION',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEUCE WIDE',
                                isSelected: false,

                            },
                            {
                                name: 'DEUCE BODY',
                                isSelected: false,
                            },
                            {
                                name: 'DEUCE T',
                                isSelected: false,

                            },
                            {
                                name: 'AD WIDE',
                                isSelected: false,

                            },
                            {
                                name: 'AD BODY',
                                isSelected: false,
                            },
                            {
                                name: 'AD T',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'PLACEMENT TO SIDELINE',
                        type: 'advanced',
                        options: [
                            {
                                name: '<40CM',
                                isSelected: false

                            },
                            {
                                name: '40-80CM',
                                isSelected: false

                            },
                            {
                                name: '>80CM',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'DEPTH',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEEP',
                                isSelected: false

                            },
                            {
                                name: 'MIDDLE',
                                isSelected: false

                            },
                            {
                                name: 'SHORT',
                                isSelected: false

                            }
                        ]
                    },
                    {
                        name: 'SPEED KM/H',
                        type: 'advanced',
                        options: [
                            {
                                name: '<140',
                                isSelected: false

                            },
                            {
                                name: '140-160',
                                isSelected: false

                            },
                            {
                                name: '160-180',
                                isSelected: false

                            },
                            {
                                name: '180-200',
                                isSelected: false

                            },
                            {
                                name: '200-220',
                                isSelected: false
                            },
                            {
                                name: '>220',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'QUALITY',
                        type: 'advanced',
                        options: [
                            {
                                name: '1-3 WEAK',
                                isSelected: false
                            },
                            {
                                name: '4-6 MEDIOCRE',
                                isSelected: false
                            },
                            {
                                name: '7-8 GOOD',
                                isSelected: false
                            },
                            {
                                name: '9 EXCELLENT',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'SERVE & VOLLEY',
                        type: 'advanced',
                        options: [
                            {
                                name: 'YES',
                                isSelected: false

                            }
                        ]
                    }
                ]
            },
            {
                name: 'Out',
                level: 'shot',
                isActive: false,
                isDisabled: false,
                filters: [
                    {
                        name: 'SERVE NUMBER',
                        type: 'advanced',
                        options: [
                            {
                                name: '1ST',
                                isSelected: false,

                            },
                            {
                                name: '2ND',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'SERVE DIRECTION',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEUCE WIDE',
                                isSelected: false,

                            },
                            {
                                name: 'DEUCE BODY',
                                isSelected: false,
                            },
                            {
                                name: 'DEUCE T',
                                isSelected: false,

                            },
                            {
                                name: 'AD WIDE',
                                isSelected: false,

                            },
                            {
                                name: 'AD BODY',
                                isSelected: false,
                            },
                            {
                                name: 'AD T',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'MISSED',
                        type: 'advanced',
                        options: [
                            {
                                name: 'LONG',
                                isSelected: false

                            },
                            {
                                name: 'NET',
                                isSelected: false
                            },
                            {
                                name: 'WIDE',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'SPEED KM/H',
                        type: 'advanced',
                        options: [
                            {
                                name: '<140',
                                isSelected: false

                            },
                            {
                                name: '140-160',
                                isSelected: false

                            },
                            {
                                name: '160-180',
                                isSelected: false

                            },
                            {
                                name: '180-200',
                                isSelected: false

                            },
                            {
                                name: '200-220',
                                isSelected: false
                            },
                            {
                                name: '>220',
                                isSelected: false
                            }
                        ]
                    },
                ]
            }
        ],
    },
    {
        name: 'Return',
        isActive: false,
        isDisabled: false,
        tabs: [
            {
                name: 'In',
                level: 'shot',
                isActive: false,
                isDisabled: false,
                filters: [
                    {
                        name: 'SERVE NUMBER',
                        type: 'advanced',
                        options: [
                            {
                                name: '1ST',
                                isSelected: false,

                            },
                            {
                                name: '2ND',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'SERVE DIRECTION',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEUCE WIDE',
                                isSelected: false,

                            },
                            {
                                name: 'DEUCE BODY',
                                isSelected: false,
                            },
                            {
                                name: 'DEUCE T',
                                isSelected: false,

                            },
                            {
                                name: 'AD WIDE',
                                isSelected: false,

                            },
                            {
                                name: 'AD BODY',
                                isSelected: false,
                            },
                            {
                                name: 'AD T',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'CONTACT LOCATION',
                        type: 'advanced',
                        options: [
                            {
                                name: 'FAR BEHIND',
                                isSelected: false

                            },
                            {
                                name: 'BEHIND BASELINE',
                                isSelected: false
                            },
                            {
                                name: 'ON THE BASELINE',
                                isSelected: false
                            },
                            {
                                name: 'INSIDE THE BASELINE',
                                isSelected: false
                            },
                        ]
                    },
                    {
                        name: 'SPEED KM/H',
                        type: 'advanced',
                        options: [
                            {
                                name: '<80',
                                isSelected: false

                            },
                            {
                                name: '80-100',
                                isSelected: false

                            },
                            {
                                name: '100-120',
                                isSelected: false

                            },
                            {
                                name: '120-140',
                                isSelected: false
                            },
                            {
                                name: '>140',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'DEPTH',
                        type: 'advanced',
                        options: [
                            {
                                name: 'VERY DEEP',
                                isSelected: false
                            },
                            {
                                name: 'DEEP',
                                isSelected: false
                            },
                            {
                                name: 'MIDDLE',
                                isSelected: false
                            },
                            {
                                name: 'SHORT',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'SERVER ON THE MOVE',
                        type: 'advanced',
                        options: [
                            {
                                name: 'MOVEMENT 2M+',
                                isSelected: false
                            },
                            {
                                name: 'PULLED OF THE COURT',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'WEAK',
                        type: 'advanced',
                        options: [
                            {
                                name: 'YES',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'SLICE RETURNS',
                        type: 'advanced',
                        options: [
                            {
                                name: 'YES',
                                isSelected: false
                            },
                            {
                                name: 'NO',
                                isSelected: false
                            },
                        ]
                    },
                    {
                        name: 'AGGRESSIVE',
                        type: 'advanced',
                        options: [
                            {
                                name: 'YES',
                                isSelected: false
                            }
                        ]
                    },
                ]
            },
            {
                name: 'Out',
                level: 'shot',
                isActive: false,
                isDisabled: false,
                filters: [
                    {
                        name: 'SERVE NUMBER',
                        type: 'advanced',
                        options: [
                            {
                                name: '1ST',
                                isSelected: false,

                            },
                            {
                                name: '2ND',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'SERVE DIRECTION',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEUCE WIDE',
                                isSelected: false,

                            },
                            {
                                name: 'DEUCE BODY',
                                isSelected: false,
                            },
                            {
                                name: 'DEUCE T',
                                isSelected: false,

                            },
                            {
                                name: 'AD WIDE',
                                isSelected: false,

                            },
                            {
                                name: 'AD BODY',
                                isSelected: false,
                            },
                            {
                                name: 'AD T',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'MISSED',
                        type: 'advanced',
                        options: [
                            {
                                name: 'WIDE',
                                isSelected: false
                            },
                            {
                                name: 'LONG',
                                isSelected: false

                            },
                            {
                                name: 'NET',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'CONTACT LOCATION',
                        type: 'advanced',
                        options: [
                            {
                                name: 'FAR BEHIND',
                                isSelected: false

                            },
                            {
                                name: 'BEHIND BASELINE',
                                isSelected: false
                            },
                            {
                                name: 'ON THE BASELINE',
                                isSelected: false
                            },
                            {
                                name: 'INSIDE THE BASELINE',
                                isSelected: false
                            },
                        ]
                    },
                    {
                        name: 'SPEED KM/H',
                        type: 'advanced',
                        options: [
                            {
                                name: '<80',
                                isSelected: false

                            },
                            {
                                name: '80-100',
                                isSelected: false

                            },
                            {
                                name: '100-120',
                                isSelected: false

                            },
                            {
                                name: '120-140',
                                isSelected: false
                            },
                            {
                                name: '>140',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'SLICE RETURNS',
                        type: 'advanced',
                        options: [
                            {
                                name: 'YES',
                                isSelected: false
                            },
                            {
                                name: 'NO',
                                isSelected: false
                            },
                        ]
                    }
                ]
            },
        ],
    },
    {
        name: 'Serve + Return',
        isActive: false,
        isDisabled: false,
        tabs: [
            {
                name: 'Serve + Return',
                level: 'shot',
                isActive: false,
                isDisabled: false,
                filters: [
                    {
                        name: 'SERVE NUMBER',
                        type: 'advanced',
                        options: [
                            {
                                name: '1ST',
                                isSelected: false,

                            },
                            {
                                name: '2ND',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'SERVE DIRECTION',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEUCE WIDE',
                                isSelected: false,

                            },
                            {
                                name: 'DEUCE BODY',
                                isSelected: false,
                            },
                            {
                                name: 'DEUCE T',
                                isSelected: false,

                            },
                            {
                                name: 'AD WIDE',
                                isSelected: false,

                            },
                            {
                                name: 'AD BODY',
                                isSelected: false,
                            },
                            {
                                name: 'AD T',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'RETURN DIRECTION',
                        type: 'advanced',
                        options: [
                            {
                                name: 'CROSS',
                                isSelected: false,

                            },
                            {
                                name: 'SHORT',
                                isSelected: false,

                            },
                            {
                                name: 'ANGLED',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'RETURN SPEED KM/H',
                        type: 'advanced',
                        options: [
                            {
                                name: '<80',
                                isSelected: false

                            },
                            {
                                name: '80-100',
                                isSelected: false

                            },
                            {
                                name: '100-120',
                                isSelected: false

                            },
                            {
                                name: '120-140',
                                isSelected: false
                            },
                            {
                                name: '>140',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'RETURN DEPTH',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEEP',
                                isSelected: false
                            },
                            {
                                name: 'MIDDLE',
                                isSelected: false
                            },
                            {
                                name: 'SHORT',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'SERVE+1',
                        type: 'advanced',
                        options: [
                            {
                                name: 'STATIONARY',
                                isSelected: false
                            },
                            {
                                name: 'ON THE MOVE',
                                isSelected: false
                            }
                        ]
                    }
                ]
            }
        ],
    },
    {
        name: 'Serve+1',
        isActive: false,
        isDisabled: false,
        tabs: [
            {
                name: 'Serve+1',
                level: 'shot',
                isActive: false,
                isDisabled: false,
                filters: [
                    {
                        name: 'SERVE NUMBER',
                        type: 'advanced',
                        options: [
                            {
                                name: '1ST',
                                isSelected: false,

                            },
                            {
                                name: '2ND',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'SERVE DIRECTION',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEUCE WIDE',
                                isSelected: false,

                            },
                            {
                                name: 'DEUCE BODY',
                                isSelected: false,
                            },
                            {
                                name: 'DEUCE T',
                                isSelected: false,

                            },
                            {
                                name: 'AD WIDE',
                                isSelected: false,

                            },
                            {
                                name: 'AD BODY',
                                isSelected: false,
                            },
                            {
                                name: 'AD T',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'S+1 CONTACT ZONE',
                        type: 'advanced',
                        options: [
                            {
                                name: 'A',
                                isSelected: false
                            },
                            {
                                name: 'B',
                                isSelected: false
                            },
                            {
                                name: 'C',
                                isSelected: false
                            },
                            {
                                name: 'D',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'S+1 DEPTH',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEEP',
                                isSelected: false,

                            },
                            {
                                name: 'MIDDLE',
                                isSelected: false,

                            },
                            {
                                name: 'SHORT',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'S+1 SHOT TYPE',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEUCE FH',
                                isSelected: false
                            },
                            {
                                name: 'MIDDLE FH',
                                isSelected: false
                            },
                            {
                                name: 'RUN AROUND FH',
                                isSelected: false
                            },
                            {
                                name: 'MIDDLE BH',
                                isSelected: false
                            },
                            {
                                name: 'AD BH',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'S+1 QUALITY',
                        type: 'advanced',
                        options: [
                            {
                                name: 'FAST',
                                isSelected: false
                            },
                            {
                                name: 'WINNER',
                                isSelected: false
                            },
                            {
                                name: 'FORCING ERROR',
                                isSelected: false
                            },
                            {
                                name: 'WEAK',
                                isSelected: false
                            },
                            {
                                name: 'RETURNER ON MOVE',
                                isSelected: false
                            },
                            {
                                name: 'RETURNER PUSHED BACK',
                                isSelected: false
                            }
                        ]
                    }
                ]
            }
        ],
    },
    {
        name: 'Return+1',
        isActive: false,
        isDisabled: false,
        tabs: [
            {
                name: 'Return+1',
                level: 'shot',
                isActive: false,
                isDisabled: false,
                filters: [
                    {
                        name: 'SERVE NUMBER',
                        type: 'advanced',
                        options: [
                            {
                                name: '1ST',
                                isSelected: false,

                            },
                            {
                                name: '2ND',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'SERVE DIRECTION',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEUCE WIDE',
                                isSelected: false,

                            },
                            {
                                name: 'DEUCE BODY',
                                isSelected: false,
                            },
                            {
                                name: 'DEUCE T',
                                isSelected: false,

                            },
                            {
                                name: 'AD WIDE',
                                isSelected: false,

                            },
                            {
                                name: 'AD BODY',
                                isSelected: false,
                            },
                            {
                                name: 'AD T',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'R+1 CONTACT ZONE',
                        type: 'advanced',
                        options: [
                            {
                                name: 'A',
                                isSelected: false
                            },
                            {
                                name: 'B',
                                isSelected: false
                            },
                            {
                                name: 'C',
                                isSelected: false
                            },
                            {
                                name: 'D',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'R+1 DEPTH',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEEP',
                                isSelected: false,

                            },
                            {
                                name: 'MIDDLE',
                                isSelected: false,

                            },
                            {
                                name: 'SHORT',
                                isSelected: false,

                            }
                        ]
                    },
                    {
                        name: 'R+1 SHOT TYPE',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEUCE FH',
                                isSelected: false
                            },
                            {
                                name: 'MIDDLE FH',
                                isSelected: false
                            },
                            {
                                name: 'RUN AROUND FH',
                                isSelected: false
                            },
                            {
                                name: 'MIDDLE BH',
                                isSelected: false
                            },
                            {
                                name: 'AD BH',
                                isSelected: false
                            }
                        ]
                    },
                    {
                        name: 'R+1 QUALITY',
                        type: 'advanced',
                        options: [
                            {
                                name: 'FAST',
                                isSelected: false
                            },
                            {
                                name: 'WINNER',
                                isSelected: false
                            },
                            {
                                name: 'FORCING ERROR',
                                isSelected: false
                            },
                            {
                                name: 'WEAK',
                                isSelected: false
                            },
                            {
                                name: 'SERVER ON MOVE',
                                isSelected: false
                            },
                            {
                                name: 'SERVER PUSHED BACK',
                                isSelected: false
                            }
                        ]
                    }
                ]
            }
        ],
    },
    {
        name: 'Shots',
        isDisabled: false,
        isActive: false,
        tabs: [
            {
                name: 'General',
                level: 'shot',
                isDisabled: false,
                isActive: false,
                filters: [
                    {
                        name: 'STATIONARY SHOTS',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEUCE FH',
                                isSelected: false,
                            },
                            {
                                name: 'MIDDLE FH',
                                isSelected: false,
                            },
                            {
                                name: 'MIDDLE BH',
                                isSelected: false,
                            },
                            {
                                name: 'RUN AROUND FH',
                                isSelected: false,
                            },
                            {
                                name: 'AD BH',
                                isSelected: false,
                            },
                        ]
                    },
                    {
                        name: 'RUNNING SHOTS',
                        type: 'advanced',
                        options: [
                            {
                                name: 'SIDEWAYS TO FH',
                                isSelected: false,
                            },
                            {
                                name: 'SIDEWAYS TO BH',
                                isSelected: false,
                            }
                        ]
                    },
                    {
                        name: 'PLAYER DEPTH',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEEP BEHIND BASELINE',
                                isSelected: false,
                            },
                            {
                                name: 'NEAR BEHIND BASELINE',
                                isSelected: false,
                            },
                            {
                                name: 'NEAR IN FRONT OF BASELINE',
                                isSelected: false,
                            },
                            {
                                name: 'BACKCOURT',
                                isSelected: false,
                            },
                            {
                                name: 'FORECOURT',
                                isSelected: false,
                            },
                        ]
                    },
                ]
            },
            {
                name: 'Passing & Approach',
                level: 'shot',
                isDisabled: false,
                isActive: false,
                filters: [
                    {
                        name: 'APPROACH SHOTS',
                        type: 'advanced',
                        options: [
                            {
                                name: 'FH TO BH',
                                isSelected: false,
                            },
                            {
                                name: 'FH TO FH',
                                isSelected: false,
                            }
                        ]
                    },
                    {
                        name: 'PASSING SHOTS',
                        type: 'advanced',
                        options: [
                            {
                                name: 'FH CROSS',
                                isSelected: false,
                            },
                            {
                                name: 'FH LINE',
                                isSelected: false,
                            },
                            {
                                name: 'BH CROSS',
                                isSelected: false,
                            },
                            {
                                name: 'BH LINE',
                                isSelected: false,
                            },
                        ]
                    },
                ]
            },
            {
                name: 'With Incoming Shot',
                level: 'shot',
                isDisabled: false,
                isActive: false,
                filters: [
                    {
                        name: 'SPEED OF INCOMING BALL',
                        type: 'advanced',
                        options: [
                            {
                                name: 'AVERAGE',
                                isSelected: false,
                            },
                            {
                                name: 'SLOWER',
                                isSelected: false,
                            },
                            {
                                name: 'FASTER',
                                isSelected: false,
                            }
                        ]
                    },
                    {
                        name: 'BALL CONTACT HEIGHT',
                        type: 'advanced',
                        options: [
                            {
                                name: 'AVERAGE',
                                isSelected: false,
                            },
                            {
                                name: 'LOWER',
                                isSelected: false,
                            },
                            {
                                name: 'HIGHER',
                                isSelected: false,
                            }
                        ]
                    },
                    {
                        name: 'DISTANCE BOUNCE TO CONTACT',
                        type: 'advanced',
                        options: [
                            {
                                name: 'AVERAGE',
                                isSelected: false,
                            },
                            {
                                name: 'CLOSER',
                                isSelected: false,
                            },
                            {
                                name: 'FURTHER',
                                isSelected: false,
                            }
                        ]
                    },
                    {
                        name: 'ROTATIONS OF INCOMING BALL',
                        type: 'advanced',
                        options: [
                            {
                                name: 'AVERAGE',
                                isSelected: false,
                            },
                            {
                                name: 'FLATTER',
                                isSelected: false,
                            },
                            {
                                name: 'HEAVIER',
                                isSelected: false,
                            }
                        ]
                    },
                ]
            },
            {
                name: 'FH & BH DTL',
                level: 'shot',
                isDisabled: false,
                isActive: false,
                filters: [
                    {
                        name: 'FH DTL DEPTH',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEEP',
                                isSelected: false,
                            },
                            {
                                name: 'SHORT',
                                isSelected: false,
                            }
                        ]
                    },
                    {
                        name: 'FH DTL PLACEMENT',
                        type: 'advanced',
                        options: [
                            {
                                name: 'CLOSE TO SIDE',
                                isSelected: false,
                            },
                            {
                                name: 'FURTHER FROM SIDE',
                                isSelected: false,
                            }
                        ]
                    },
                    {
                        name: 'BH DTL DEPTH',
                        type: 'advanced',
                        options: [
                            {
                                name: 'DEEP',
                                isSelected: false,
                            },
                            {
                                name: 'SHORT',
                                isSelected: false,
                            }
                        ]
                    },
                    {
                        name: 'BH DTL PLACEMENT',
                        type: 'advanced',
                        options: [
                            {
                                name: 'CLOSE TO SIDE',
                                isSelected: false,
                            },
                            {
                                name: 'FURTHER FROM SIDE',
                                isSelected: false,
                            }
                        ]
                    }
                ]
            },
            {
                name: 'Volley',
                level: 'shot',
                isDisabled: false,
                isActive: false,
                filters: [
                    {
                        name: 'GENERAL',
                        type: 'advanced',
                        options: [
                            {
                                name: 'ALL',
                                isSelected: false,
                            }
                        ]
                    },
                    {
                        name: 'INCOMING BALL',
                        type: 'advanced',
                        options: [
                            {
                                name: 'FAST INCOMING BALL',
                                isSelected: false,
                            },
                            {
                                name: 'SLOW INCOMING BALL',
                                isSelected: false,
                            }
                        ]
                    },
                    {
                        name: 'NET DISTANCE',
                        type: 'advanced',
                        options: [
                            {
                                name: 'CLOSE TO THE NET',
                                isSelected: false,
                            },
                            {
                                name: 'FURTHER FROM NET',
                                isSelected: false,
                            }
                        ]
                    },
                ]
            }
        ],
    },
    {
        name: 'Rally Errors',
        isActive: false,
        isDisabled: false,
        tabs: [
            {
                name: 'Rally Errors',
                level: 'shot',
                isActive: false,
                isDisabled: false,
                filters: [
                    {
                        name: 'VARIANTS',
                        type: 'advanced',
                        options: [
                            {
                                name: 'UNFORCED ERRORS DEUCE FH',
                                isSelected: false,
                            },
                            {
                                name: 'UNFORCED ERRORS MIDDLE FH',
                                isSelected: false,
                            },
                            {
                                name: 'UNFORCED ERRORS RUN AROUND FH',
                                isSelected: false,
                            },
                            {
                                name: 'UNFORCED ERRORS MIDDLE BH',
                                isSelected: false,
                            },
                            {
                                name: 'UNFORCED ERRORS AD BH',
                                isSelected: false,
                            },
                            {
                                name: 'FH CROSS ERROR',
                                isSelected: false,
                            },
                            {
                                name: 'BH CROSS ERROR',
                                isSelected: false,
                            }
                        ]
                    },
                ]
            }
        ],
    },
    {
        name: 'Rally Patterns',
        isActive: false,
        isDisabled: false,
        tabs: [
            {
                name: 'Rally Patterns',
                level: 'shot',
                isDisabled: false,
                isActive: false,
                filters: [
                    {
                        name: 'VARIANTS',
                        type: 'advanced',
                        options: [
                            {
                                name: 'FH CROSS THEN DTL',
                                isSelected: false,
                            },
                            {
                                name: 'BH CROSS THEN DTL',
                                isSelected: false,
                            },
                            {
                                name: 'FH CROSS THEN BH',
                                isSelected: false,
                            },
                            {
                                name: 'BH CROSS THEN FH',
                                isSelected: false,
                            }
                        ]
                    },
                ]
            }
        ],
    }
];


export default newFiltersModel;