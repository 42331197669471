import Modal from "./Modal";
import { useGetPlayersQuery, useGetFilteredMatchesMutation } from "../../api/completeMatchesSlice";
import MatchesFilters from "./MatchesFilters";
import MatchesList from "./MatchesList";
import Spinner from "../../../UI/Spinner";
import { useEffect, useState } from "react";
import {
  setSelectedMatches,
  setMatchesFilter,
  setMatches,
  setGeneralFilters,
  setOpponents,
  selectOpponents,
  selectMatchesFilter,
  selectMatches,
  selectSelectedMatches,
  selectGeneralFilters,
} from "../store/matchesSlice";
import { useDispatch, useSelector } from "react-redux";
import classes from "./MatchesList.module.css";
import FiltersTags from "./FiltersTags";
const Matches = () => {
  const dispatch = useDispatch();
  const matchesFilter = useSelector(selectMatchesFilter);
  const selectedMatches = useSelector(selectSelectedMatches);
  const generalFilters = useSelector(selectGeneralFilters);
  const matches = useSelector(selectMatches);
  const opponents = useSelector(selectOpponents);
  const [currentFiltersSnapshot, setCurrentFiltersSnapshot] = useState({
    matches,
    selectedMatches,
    matchesFilter,
  });
  const { data: players, isLoading, error, status } = useGetPlayersQuery();

  const [getFilteredMatches, filteredMatches] = useGetFilteredMatchesMutation();

  useEffect(() => {
    if (players && !matchesFilter.firstPlayer) {
      let selectedFirstPlayer = null;
      let userPlayers = players.filter((p) => p.isUserPlayer === true);
      if (userPlayers.length > 0) {
        selectedFirstPlayer = {
          playerName: userPlayers[0].name,
          playerId: userPlayers[0].playerId,
          category: userPlayers[0].category,
        };
      }
      dispatch(
        setMatchesFilter({
          ...matchesFilter,
          firstPlayer: selectedFirstPlayer,
        })
      );
    }
  }, [players]);

  useEffect(() => {
    if (filteredMatches.data) {
      const newMatches = filteredMatches.data.matches.map((m) => ({
        ...m,
        isSelected: currentFiltersSnapshot.selectedMatches.some((sm) => sm.matchId === m.matchId),
      }));
      if (currentFiltersSnapshot.matchesFilter.secondPlayersIds.length === 0) {
        dispatch(setOpponents(filteredMatches.data.opponents));
      }
      setCurrentFiltersSnapshot((prevState) => ({
        ...prevState,
        matches: newMatches,
      }));
    }
  }, [filteredMatches.data]);

  useEffect(() => {
    if (players) ApplyFiltering();
  }, [currentFiltersSnapshot.matchesFilter]);

  const ApplyFiltering = () => {
    const updatedMatchesFilter = {
      firstPlayerId: currentFiltersSnapshot.matchesFilter.firstPlayer?.playerId,
      secondPlayersIds: currentFiltersSnapshot.matchesFilter.secondPlayersIds,
      surfaceTypes: currentFiltersSnapshot.matchesFilter.surfaceTypes,
      handedness: currentFiltersSnapshot.matchesFilter.handedness,
    };
    if (updatedMatchesFilter.surfaceTypes.length === 0) {
      updatedMatchesFilter.surfaceTypes = [0, 1, 2, 3, 4];
    }
    getFilteredMatches(updatedMatchesFilter);
  };

  const matchesMultipleSelection = (targetSelectionValue) => {
    setCurrentFiltersSnapshot((prevState) => {
      const newMatches = prevState.matches.map((m) => ({
        ...m,
        isSelected: targetSelectionValue,
      }));

      let newSelectedMatches = [];
      if (targetSelectionValue) {
        const matchesAddedToSelection = newMatches.filter(
          (m) => !prevState.selectedMatches.some((selected) => selected.matchId === m.matchId)
        );
        newSelectedMatches = [...prevState.selectedMatches, ...matchesAddedToSelection];
      } else {
        newSelectedMatches = prevState.selectedMatches.filter(
          (selected) => !newMatches.some((m) => m.matchId === selected.matchId)
        );
      }

      return {
        ...prevState,
        matches: newMatches,
        selectedMatches: newSelectedMatches,
      };
    });
  };

  const ApplySnapshotHandler = () => {
    if (filteredMatches.data.length === 0) currentFiltersSnapshot.selectedMatches = [];

    dispatch(
      setGeneralFilters({
        ...generalFilters,
        player: [currentFiltersSnapshot.matchesFilter.firstPlayer.playerId],
      })
    );

    dispatch(setMatches(currentFiltersSnapshot.matches));
    dispatch(setSelectedMatches(currentFiltersSnapshot.selectedMatches));
    dispatch(setMatchesFilter(currentFiltersSnapshot.matchesFilter));
  };

  const MatchSelectionToggleHandler = (match) => {
    const newMatch = { ...match };
    newMatch.isSelected = !newMatch.isSelected;

    setCurrentFiltersSnapshot((prevState) => {
      const newMatches = prevState.matches.map((m) => (m.matchId === newMatch.matchId ? newMatch : m));
      let newSelectedMatches = [];
      if (newMatch.isSelected) {
        newSelectedMatches = [...prevState.selectedMatches, newMatch];
      } else {
        newSelectedMatches = prevState.selectedMatches.filter((m) => m.matchId !== newMatch.matchId);
      }

      return {
        ...prevState,
        matches: newMatches,
        selectedMatches: newSelectedMatches,
      };
    });
  };

  const setSnapshotMatchesFilter = (newMatchesFilter, firstPlayerChange = false) => {
    setCurrentFiltersSnapshot((prevState) => ({
      ...prevState,
      selectedMatches: firstPlayerChange ? [] : prevState.selectedMatches,
      matchesFilter: newMatchesFilter,
    }));
  };

  const selectMatchesClicked = () => {
    setCurrentFiltersSnapshot({
      matches,
      selectedMatches,
      matchesFilter,
    });
    if (players) ApplyFiltering();
  };

  if (isLoading)
    return (
      <div style={{ height: "3rem" }} className="card mb-3 row justify-content-center ml-1 mr-1">
        <Spinner />
      </div>
    );
  else if (players)
    return (
      <div className={`card mb-3 ml-1 mr-1 ${classes["matches-card"]}`}>
        <div className="d-flex flex-wrap p-1 align-items-center">
          <div className="mr-auto">
            <button
              style={{ width: "fit-content", height: "fit-content" }}
              className="btn btn-light ml-1 mb-1"
              type="button"
              data-toggle="modal"
              data-target="#matchesFilterModal"
              onClick={selectMatchesClicked}
            >
              Select Matches ({selectedMatches.length}) <i className="fas fa-filter"></i>
            </button>
          </div>
          {players && opponents && <FiltersTags players={players} opponents={opponents} />}
        </div>
        <Modal onApplyFilter={ApplySnapshotHandler} countSelected={currentFiltersSnapshot.selectedMatches.length}>
          {
            <MatchesFilters
              players={players}
              opponents={opponents}
              matchesFilter={currentFiltersSnapshot.matchesFilter}
              setSnapshotMatchesFilter={setSnapshotMatchesFilter}
            />
          }
          {filteredMatches.isLoading ? (
            <Spinner />
          ) : currentFiltersSnapshot.matches && !filteredMatches.isLoading ? (
            <MatchesList
              matches={currentFiltersSnapshot.matches}
              onToggleMatch={MatchSelectionToggleHandler}
              matchesMultipleSelection={matchesMultipleSelection}
            />
          ) : (
            <div className="d-flex justify-content-center">
              <h4>Please select a player.</h4>
            </div>
          )}
          {currentFiltersSnapshot && currentFiltersSnapshot.selectedMatches.length > 0 && <hr />}
          {currentFiltersSnapshot && currentFiltersSnapshot.selectedMatches.length > 0 && (
            <div className={`d-flex justify-content-center ${classes["table-container"]}`}>
              <table
                className={`table table-sm table-dark table-responsive scrollbar perfect-scrollbar ${classes["matches-table"]}`}
              >
                <caption>Selected Matches</caption>
                <thead>
                  <tr>
                    <th scope="col">
                      {/* <button
                      className="btn btn-warning text-nowrap"
                      onClick={() =>
                        setCurrentFiltersSnapshot((prevState) => ({
                          ...prevState,
                          selectedMatches: [],
                          matches: prevState.matches.map((m) => ({ ...m, isSelected: false })),
                        }))
                      }
                    >
                      Clear All
                    </button> */}
                      <input
                        type="checkbox"
                        readOnly
                        checked={true}
                        onClick={() =>
                          setCurrentFiltersSnapshot((prevState) => ({
                            ...prevState,
                            selectedMatches: [],
                            matches: prevState.matches.map((m) => ({ ...m, isSelected: false })),
                          }))
                        }
                      />
                    </th>
                    <th scope="col" className="text-nowrap">
                      First Player
                    </th>
                    <th scope="col" className="text-nowrap">
                      Second Player
                    </th>
                    <th scope="col" className="text-nowrap">
                      Event
                    </th>
                    <th scope="col" className="text-nowrap">
                      Round
                    </th>
                    <th scope="col" className="text-nowrap">
                      Surface
                    </th>
                    <th scope="col" className="text-nowrap">
                      Winner
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentFiltersSnapshot.selectedMatches.map((match) => (
                    <tr key={match.matchId}>
                      <th scope="row">
                        <input
                          type="checkbox"
                          checked={match.isSelected}
                          onChange={() => MatchSelectionToggleHandler(match)}
                        />
                      </th>
                      <td className="text-nowrap">{match.firstPlayerName}</td>
                      <td className="text-nowrap">{match.secondPlayerName}</td>
                      <td className="text-nowrap">{match.eventName}</td>
                      <td className="text-nowrap">{match.roundName}</td>
                      <td className="text-nowrap">{match.surface}</td>
                      <td className="text-nowrap">{match.winnerName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Modal>
      </div>
    );
  else
    return (
      <div style={{ height: "3rem" }} className="card mb-3 row justify-content-center ml-1 mr-1">
        <div className="align-items-center justify-content-center">
          <h4>
            {" "}
            {status} {error.status} {JSON.stringify(error.error)}
          </h4>
        </div>
      </div>
    );
};

export default Matches;
