import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { activateRankedPlayer } from "./ranksSlice";


export default function RankedPlayerRowItem({ player }) {
    let [hovered, setHovered] = useState(false);
    const dispatch = useDispatch();

    return (
        <div>
            <div className={"row pb-2" + (hovered ? " text-white" : "")} onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}>
                <span className="badge badge-soft-dark rounded-capsule mr-1">{player.rank}</span>
                <span className="player-list-item-label-text" onClick={e => {
                    e.preventDefault()
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    dispatch(activateRankedPlayer(player.playerId));
                }}>
                    {!hovered ? player.oncName : <u style={{ cursor: "pointer" }}>{player.oncName}</u>}
                </span>
            </div>
            <div className="row">
                <span className="badge badge-soft-warning rounded-capsule">{player.points}</span>
            </div>
        </div>
    );
}