import React from "react";
import { Link } from "react-router-dom";
import PathConstants from "../routes/pathConstants";


export default function NotAuthorizedPage() {
    return (
        <div className="container" data-layout="container">
            <div className="row flex-center min-vh-100 py-6 text-center">
                <div className="col-sm-10 col-md-8 col-lg-6 col-xxl-5"><a className="d-flex flex-center mb-4" href="../../index.html"><span className="text-sans-serif font-weight-extra-bold fs-5 d-inline-block">GSA Client App</span></a>
                    <div className="card">
                        <div className="card-body p-4 p-sm-5">
                            <div className="display-1 text-300 fs-error">401</div>
                            <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">You are not authorized to access this page</p>
                            <hr />
                            <Link className="btn btn-primary btn-sm mt-3" to={PathConstants.INDEX}>Take me home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}