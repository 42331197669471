import React from "react";
import { useSelector } from "react-redux";
import { selectRankedPlayers } from "./ranksSlice";
import RankedPlayerRowItem from "./RankedPlayerRowItem";


export default function RankedPlayersList() {
    const players = useSelector(selectRankedPlayers);
    let count = 0;
    if (players === undefined || players === null) {
        count = 0;
    }
    else {
        count = players.length;
    }
    return (
        <div className="card mt-1 min-vh-50 max-vh-75">
            <div className="card-header">
                <div className="row d-flex">
                    <div className="col-auto">
                        <h5>Ranked Players ({count})</h5>
                    </div>
                </div>
            </div>
            <div className="card-body p-0 scrollbar perfect-scrollbar">
                <div className="list-group">
                    {players.map(player => (
                        <div className={"list-group-item list-group-item-action flex-column align-items-start " + (player.isActive ? " bg-200 text-white" : "")} key={player.playerId}>
                            <RankedPlayerRowItem key={player.playerId} player={player} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}