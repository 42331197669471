const opponentTypeOptions = [
    { label: "All Players", value: 0 },
    { label: "Right-Handed Players", value: 1 },
    { label: "Left-Handed Players", value: 2 },
    { label: "One Player", value: 3 },
];

const backhandTypeOptions = [
    { label: "Any", value: 0 },
    { label: "One-Handed", value: 1 },
    { label: "Two-Handed", value: 2 }
];

const surfaceOptions = [
    { value: 0, label: "Hard" },
    { value: 1, label: "Clay" },
    { value: 2, label: "Grass" },
    { value: 3, label: "Carpet" },
];

const yearsOptions = Array.from({ length: 13 }, (_, i) => i + 2012).map((year) => ({ label: year, value: year }));

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#0e1c2f', borderColor: '#344050', color: '#d8e2ef' }),
    input: styles => ({ ...styles, color: '#d8e2ef' }),
    option: (styles) => {
        return {
            ...styles,
            backgroundColor: '#0e1c2f',
            color: '#d8e2ef',
        };
    },
    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: '#d8e2ef',
            color: '#0e1c2f',
        };
    },
    singleValue: (styles) => {
        return {
            ...styles,
            color: '#d8e2ef',
        };
    },
};

const getCurrentProgress = (status) => {
    let currentStep;
    switch (status) {
        case "Processing Inputs":
            currentStep = 0;
            break;
        case "Collecting Matches":
            currentStep = 1;
            break;
        case "Analyzing Matches":
            currentStep = 2;
            break;
        case "Generating Statistics":
            currentStep = 3;
            break;
        case "Generating Report":
            currentStep = 4;
            break;
        case "Uploading Report":
            currentStep = 5;
            break;
        case "Succeeded":
            return "";
            break;
        case "Failed":
            return "";
        default:
            currentStep = 0;
            break;
    }
    return `${Math.round(currentStep / 6 * 100)}%`;
};

export {
    opponentTypeOptions,
    backhandTypeOptions,
    yearsOptions,
    surfaceOptions,
    colourStyles,
    getCurrentProgress
}