import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    players: [],
    activePlayerIndex: null
};

const ranksSlice = createSlice({
    name: 'ranks',
    initialState,
    reducers: {
        setRankedPlayers(state, action) {
            state.players = action.payload;
        },
        activateRankedPlayer(state, action) {
            for (let player of state.players) {
                if (player.playerId === action.payload) {
                    player.isActive = true;
                    state.activePlayerIndex = state.players.indexOf(player);
                }
                else {
                    player.isActive = false;
                }
            }
        },
        reset(state, action) {
            state.players = [];
            state.activePlayerIndex = null;
        },
    },
});

export const { setRankedPlayers, activateRankedPlayer, reset } = ranksSlice.actions;

export const selectRankedPlayers = state => state.ranks.players;
export const selectActivePlayerIndex = state => state.ranks.activePlayerIndex;

export default ranksSlice.reducer;