import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import getAuthToken from '../auth/authManager';
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    mode: 'cors',
    prepareHeaders: (headers) => {
      const token = getAuthToken();
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: builder => ({
    getRankedPlayers: builder.query({
      query: ({ category, limit }) => `/players/ranks?category=${category}&limit=${limit}`,
      transformResponse: (response) => {
        response.isSelected = false;
        response.isActive = false;
        for (let i = 0; i < response.length; i++) {
          response[i].isSelected = false;
          response[i].isActive = false;
        }
        return response;
      }
    })
  })
})


export const { 
  useGetRankedPlayersQuery
} = apiSlice