import PointItem from "./PointItem";

const PointsList = ({ match }) => {
  const filteredPoints = match.pointList.filter((point) => {
    return point.isSelected && point.shots.some((s) => s.isSelected);
  });

  return (
    <div className="mt-2 mb-2">
      <button
        id={`header-${match.matchId}`}
        className="d-flex ml-0 mb-2 btn text-decoration-none text-left"
        type="button"
        data-toggle="collapse"
        data-target={`#collapse-${match.matchId}`}
        aria-expanded="true"
        aria-controls={`collapse-${match.matchId}`}
      >
        <span
          className="fas fa-caret-right accordion-icon mr-3"
          data-fa-transform="shrink-2"
        ></span>
        <span className=" font-weight-medium text-sans-serif text-900">
          {" "}
          {match.firstPlayerName} vs {match.secondPlayerName} -{" "}
          {match.eventName}
        </span>
      </button>
      <div
        className="collapse show"
        id={`collapse-${match.matchId}`}
        aria-labelledby={`header-${match.matchId}`}
      >
        <div className="list-group">
          {filteredPoints.length === 0 && <span className="text-warning text-center">No Points are Selected</span>}
          {filteredPoints.length > 0 &&
            filteredPoints.map((point) => (
              <PointItem
                key={match.matchId + "-" + point.pointId}
                match={match}
                point={point}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default PointsList;
