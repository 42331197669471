import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleFilter, selectSelectedFilters, selectExistingFilters } from "../store/matchesSlice";

export default function MultipOptions({ category, tab, filterModel, withLabel = true }) {
  const dispatch = useDispatch();
  const selectedFilters = useSelector(selectSelectedFilters);
  const existingFilters = useSelector(selectExistingFilters);

  const filterIsSelected = (option) => {
    return (
      selectedFilters?.find((f) => f.name == filterModel.name)?.options.find((f) => f.name == option.name) !== undefined
    );
  };
  function filterBtn(option, index) {
    let targetClasses = "btn btn-sm rounded-capsule btn-falcon-default text-nowrap m-1 fs--1";

    const filterSelected = filterIsSelected(option);
    const filterExists = existingFilters?.some(
      (f) =>
        (f.category?.toUpperCase() === category.toUpperCase() &&
          f.tab?.toUpperCase() === tab.toUpperCase() &&
          f.tabRow?.toUpperCase() === filterModel.name?.toUpperCase() &&
          f.filter?.toUpperCase() === option.name?.toUpperCase()) ||
        (f.category?.toUpperCase() === category.toUpperCase() &&
          category.toUpperCase() === tab.toUpperCase() &&
          filterModel.name?.toUpperCase() === "VARIANTS" &&
          f.filter?.toUpperCase() === option.name?.toUpperCase())
    );

    if (filterSelected && !filterExists) {
      dispatch(
        toggleFilter({
          category: category,
          tab: tab,
          row: filterModel.name,
          option: option.name,
        })
      );
    }
    return (
      <div key={`${category}-${tab}-${filterModel.name}-${index}`}>
        <button
          onClick={(e) => {
            dispatch(
              toggleFilter({
                category: category,
                tab: tab,
                row: filterModel.name,
                option: option.name,
              })
            );
          }}
          style={{ backgroundColor: filterSelected ? "#daa520" : "", borderColor: filterSelected ? "#daa520" : "" }}
          disabled={!filterExists}
          key={index}
          className={targetClasses}
        >
          {option.name.toUpperCase()}
        </button>
      </div>
    );
  }

  return (
    <div className="row align-items-center mb-2">
      {withLabel && (
        <div className="col-sm-3">
          <label className="font-weight-bold">
            <span className="fs-0 align-middle">{filterModel.name.toUpperCase()}</span>
          </label>
        </div>
      )}
      <div className="col-sm-9">
        <div className="d-flex flex-wrap justify-content-start btn-group" role="group" aria-label="First group">
          {filterModel.options.map((option, index) => filterBtn(option, index))}
        </div>
      </div>
    </div>
  );
}
