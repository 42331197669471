import { Routes, Route } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import PathConstants from "./pathConstants"

import Layout from '../Layout';
import RanksPage from '../features/ranks/RanksPage';
import CompleteMatchesPage from '../features/completeMatches/CompleteMatchesPage';
import CustomClipsPage from '../features/customClips/CustomClipsPage';
import ReportGenerationPage from '../features/reportGeneration/ReportGenerationPage';
import ReportPreviewPage from '../features/reportPreview/ReportPreviewPage';
import IndexPage from '../pages/indexPage';
const AppRoutes = () => {
    return (
        <Routes>
            <Route path={PathConstants.REPORTPREVIEW} element={<ReportPreviewPage />} />
            <Route path="/" element={<Layout />}>
                <Route path='' element={<IndexPage />} />
                <Route path={PathConstants.RANKS} element={<RanksPage />} />
                <Route path={PathConstants.SHOTSVIEW} element={<CompleteMatchesPage />} />
                <Route path={PathConstants.CUSTOMCLIPS} element={<CustomClipsPage />} />
                <Route path={PathConstants.REPORTGENERATION} element={<ReportGenerationPage />} />
            </Route>
            <Route path='/login/callback' element={<LoginCallback loadingElement={<div>Loading...</div>} />} />
        </Routes>
    );
};

export default AppRoutes;