import classes from "./ButtonGroup.module.css";

const ButtonGroup = ({ options, field, isDisabled, selectedOptions, setSelectedOptions }) => {
  const optionSelectionHandler = (option) => {
    if (selectedOptions.includes(option.value)) {
      setSelectedOptions(selectedOptions.filter((o) => o !== option.value));
    } else {
      setSelectedOptions([...selectedOptions, option.value]);
    }
  };

  return (
    <div style={{ cursor: isDisabled ? "not-allowed" : "pointer" }} className="d-flex flex-wrap">
      {options.map((option) => (
        <button
          key={`${field}-${option.value}`}
          onClick={() => optionSelectionHandler(option)}
          className={`list-group-item list-group-item-action text-center px-1 col-${12 / options.length}
          ${classes["button-group-item"]} ${selectedOptions.includes(option.value) ? "bg-500 text-white" : ""}`}
          disabled={isDisabled}
        >
          {option.displayName}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
