import { useSelector, useDispatch } from "react-redux";
import MultipOptions from "./MultipOptions";
import { selectActiveMainFilter, activateTab } from "../store/matchesSlice";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import classes from "./Filters.module.css";
import { textToPascalCase } from "../../../utils/app-utils";
const Filters = () => {
  const dispatch = useDispatch();
  const activeMainFilter = useSelector(selectActiveMainFilter);
  const tabs = activeMainFilter.tabs.map((filter) => {
    return {
      name: filter.name,
      level: filter.level,
      filters: filter.filters,
      isDisabled: filter.isDisabled,
    };
  });

  return (
    <div className="card" style={{ height: "fit-content" }}>
      <div className="card-body">
        <Tabs selectedTabClassName={classes["gsa-react-tabs__tab--selected"]}>
          <TabList>
            {tabs.map((tab, index) => {
              return (
                <Tab
                  disabled={tab.isDisabled}
                  onClick={() =>
                    !tab.isDisabled &&
                    dispatch(
                      activateTab({
                        category: activeMainFilter.name,
                        tab: tab.name,
                      })
                    )
                  }
                  key={index}
                >
                  {textToPascalCase(tab.name)}
                </Tab>
              );
            })}
          </TabList>
          {tabs.map((tab, index) => {
            return (
              <TabPanel key={index}>
                {tab.filters.map((filterModel, index) => {
                  return (
                    <MultipOptions
                      category={activeMainFilter.name}
                      key={index}
                      level={tab.level}
                      tab={tab.name}
                      filterModel={filterModel}
                    />
                  );
                })}
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default Filters;
