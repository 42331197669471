
const ActionButton = ({ children, onClick, disabled, classes }) => {
    return (
        <button
            disabled={disabled}
            className={`btn btn-primary btn-sm ${classes}`}
            type="button"
            onClick={onClick}
        >
            {children}
        </button>
    );
}


export default ActionButton;