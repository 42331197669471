export function secondsToTime(secs) {
  let hours = Math.floor(secs / (60 * 60));
  let minutes = Math.floor((secs % (60 * 60)) / 60);
  let seconds = Math.floor(secs % 60);

  //show numbers as 00:00:00
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${hours}:${minutes}:${seconds}`;
};

export function formatUtcDate(utcDateTimeString) {
  const utcDate = new Date(utcDateTimeString);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  };

  const localDateString = utcDate.toLocaleDateString(undefined, options);
  return localDateString;
}

export function formatDetailedUtcDate(utcDatetimeString) {
  const utcDate = new Date(utcDatetimeString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const localDateString = utcDate.toLocaleDateString(undefined, options);
  return localDateString;
}

export function formatShortDetailedUtcDate(utcDatetimeString) {
  const utcDate = new Date(utcDatetimeString);

  const day = utcDate.getDate().toString().padStart(2, '0');
  const month = (utcDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = utcDate.getFullYear();

  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };
  const timeString = utcDate.toLocaleTimeString(undefined, options);

  const formattedDate = `${day}/${month}/${year} ${timeString}`;

  return formattedDate;
}


export function textToPascalCase(text) {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export const customReactDataTableStyles = {
  table: {
    style: {
      maxHeight: '65vh',
      overflowY: 'auto',
      overflowX: 'auto',
    },
  },
  headCells: {
    style: {
      fontSize: '1.1rem',
      fontWeight: 'bold',
      color: '#FFFFFF',
      backgroundColor: '#343a40',
      flex: '1',
      justifyContent: 'center',
      minWidth: '10%',
    },
  },
  cells: {
    style: {
      color: '#FFFFFF',
      backgroundColor: '#182230',
      flex: '1',
      minWidth: 'fit-content',
      justifyContent: 'center',
    },
  },
};