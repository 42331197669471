import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetClientReportsQuery } from "../api/reportsSlice";
import StdReportGeneration from "./std/StdReportGeneration";
import { selectIsVideoClient, selectUser, selectIsAllowedReportGeneration } from "../auth/authSlice";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import Spinner from "../../UI/Spinner";

const ReportGenerationPage = () => {
    const { data: clientReports, refetch: refetchReportExecutionRecord } = useGetClientReportsQuery();

    const stdReports = clientReports?.filter(report => report.reportTypeId === 1);

    const user = useSelector(selectUser);
    const isVideoClient = useSelector(selectIsVideoClient);
    const isAllowedReportGeneration = useSelector(selectIsAllowedReportGeneration);

    useEffect(() => {
        if (clientReports) {
            const reportIsProcessing = (report) => {
                const completedStatuses = ["Succeeded", "Failed", "Deleted"];
                return !completedStatuses.includes(report.reportStatus);
            };
            const processingReportExists = clientReports.some(reportIsProcessing);

            if (!processingReportExists) return;

            const interval = setInterval(async () => {
                await refetchReportExecutionRecord();
            }, 3000);

            return () => clearInterval(interval);
        }

    }, [clientReports]);

    if (!user || isAllowedReportGeneration == null) return <Spinner />;
    else if (!isVideoClient || isAllowedReportGeneration === false) return <NotAuthorizedPage />;


    return (
        <div>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#standard-reports" role="tab" aria-controls="standard-reports" aria-selected="true">Standard Reports</a>
                </li>
            </ul>
            <div className="tab-content pt-3 pr-3 pb-3" >
                <div id="standard-reports" className="tab-pane fade show active">
                    <StdReportGeneration reports={stdReports} />
                </div>
            </div>
        </div>
    );
}
export default ReportGenerationPage;
