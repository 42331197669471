import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import getAuthToken from '../auth/authManager';

export const userSlice = createApi({
    reducerPath: 'user',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        mode: 'cors',
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        },
    }),
    endpoints: builder => ({
        getUserFeatures: builder.query({
            query: () => `/account/feature`,
        })
    })
})

export const {
    useGetUserFeaturesQuery
} = userSlice