import { useSelector } from "react-redux";
import { selectMatchesFilter, selectSelectedMatches } from "../store/matchesSlice";
import classes from "./Matches.module.css";

const surfaceOptions = {
  0: "Clay",
  1: "Grass",
  2: "Carpet",
  3: "Hard Court",
  4: "Acrylic",
};

const FiltersTags = ({ players, opponents }) => {
  const matchesFilter = useSelector(selectMatchesFilter);
  const selectedMatches = useSelector(selectSelectedMatches);

  const firstPlayer = players.find((p) => p.playerId === matchesFilter.firstPlayer?.playerId);

  const uniqueSecondPlayersIds = [
    ...new Set(
      selectedMatches.map((m) => (m.firstPlayerId === firstPlayer.playerId ? m.secondPlayerId : m.firstPlayerId))
    ),
  ];

  return (
    <div className={classes["badges-container"]}>
      {matchesFilter.firstPlayer?.playerId && (
        <div className="d-flex flex-wrap flex-column justify-content-center badge badge-soft-info ml-1">
          <div className="text-left">{firstPlayer.name}</div>
        </div>
      )}
      {uniqueSecondPlayersIds.length > 0 && (
        <div className="d-flex flex-wrap flex-column justify-content-center badge badge-soft-info ml-1">
          {opponents
            .filter((p) => uniqueSecondPlayersIds.some((id) => id === p.playerId))
            .map((p) => (
              <div key={`second-player-${p.playerId}`} className="text-left">
                {p.name}
              </div>
            ))}
        </div>
      )}
      {matchesFilter.surfaceTypes.length > 0 && (
        <div className="d-flex flex-wrap flex-column justify-content-center badge badge-soft-info ml-1">
          {matchesFilter.surfaceTypes.map((id) => (
            <div key={`surface-${id}`} className="text-left">
              {surfaceOptions[id]}
            </div>
          ))}
        </div>
      )}
      {matchesFilter.handedness != null && (
        <div className="d-flex flex-wrap flex-column justify-content-center badge badge-soft-info ml-1">
          <div className="text-left">{matchesFilter.handedness === 0 ? "Right" : "Left"}</div>
        </div>
      )}
    </div>
  );
};

export default FiltersTags;
