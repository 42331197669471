const Modal = ({ children, onApplyFilter, countSelected }) => {
  return (
    <div
      className="modal fade"
      id="matchesFilterModal"
      role="dialog"
      aria-labelledby="matchesFilterModalLabel"
      aria-hidden="true"
    >
      <div style={{ width: "fit-content", minWidth: "60%" }} className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="matchesFilterModalLabel">
              Filter Matches
            </h3>
            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
              <span className="font-weight-light" aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <h5 className="mr-auto">Currently {countSelected} matches selected</h5>
            <button className="btn btn-secondary btn-md" type="button" data-dismiss="modal">
              Cancel
            </button>
            <button className="btn btn-warning btn-md" type="button" data-dismiss="modal" onClick={onApplyFilter}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
