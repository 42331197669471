import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setRankedPlayers } from "./ranksSlice";
import { useGetRankedPlayersQuery } from "../api/apiSlice";
import RankedPlayersList from "./rankedPlayersList";
import { useSelector } from "react-redux";
import { selectIsAdmin, selectUser } from "../auth/authSlice";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import Spinner from "../../UI/Spinner";

export default function RanksPage() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);
    const { data: players, isLoading, error, isSuccess, status } = useGetRankedPlayersQuery({ category: 1, limit: 10 });

    useEffect(() => {
        if (isSuccess) {
            dispatch(setRankedPlayers(players));
        }
    }, [isSuccess, error]);

    if(!user) return <Spinner />;
    else if (!isAdmin) return <NotAuthorizedPage />;
    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>
         {status} {error.status} {JSON.stringify(error.error)}
    </div>;

    return (
        <div className="row">
            <div className="col-lg-3 mb-3">
                <RankedPlayersList />
            </div>
        </div>
    );
}