import { useSelector } from "react-redux";
import { selectCurrentlyPlayingShot } from "../store/videoPlayerSlice";
import classes from "./PointSelection.module.css";

const ShotItem = (props) => {
  const currentlyPlayingShot = useSelector(selectCurrentlyPlayingShot);
  let activeClasses =
    currentlyPlayingShot?.shotId === props.shot.shotId
      ? classes["playing-shot"]
      : "";
  activeClasses += props.shot.isSelected
    ? " " + classes["active-circle"]
    : " " + classes["inactive-circle"];
  return <div className={activeClasses}></div>;
};

export default ShotItem;
