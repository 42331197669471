import { createSlice, current } from "@reduxjs/toolkit";

const initialVideoPlayerState = {
  matchesPointList: null,
  filteredMatches: null,
  selectedPointsIds: null,
  currentlyPlayingMatch: null,
  currentlyPlayingPoint: null,
  currentlyPlayingShot: null,
  isPlaying: false,
  prevShotsCount: 0,
  nextShotsCount: 1,
  navigateToPoint: false,
};

const videoPlayerSlice = createSlice({
  name: "videoPlayer",
  initialState: initialVideoPlayerState,
  reducers: {
    setMatchList(state, action) {
      const newState = { ...current(state) };
      const filteredMatches = getFilteredMatches(
        action.payload,
        newState.prevShotsCount,
        newState.nextShotsCount
      );

      if (filteredMatches.length <= 0) {
        return {
          ...initialVideoPlayerState,
          matchesPointList: action.payload,
          filteredMatches: filteredMatches,
          prevShotsCount: newState.prevShotsCount,
          nextShotsCount: newState.nextShotsCount,
        };
      }

      newState.matchesPointList = action.payload;
      newState.filteredMatches = filteredMatches;
      newState.selectedPointsIds = filteredMatches.flatMap((m) => m.pointList.map((p) => p.pointId));

      const playingMatchExists = filteredMatches.find(
        (m) => m.matchId === newState.currentlyPlayingMatch?.matchId
      );

      // if (!playingMatchExists) {
      if (true) {
        newState.currentlyPlayingMatch = filteredMatches[0];
        newState.currentlyPlayingPoint = filteredMatches[0].pointList[0];
        newState.currentlyPlayingShot = filteredMatches[0].pointList[0].shots[0];
      } else {
        newState.currentlyPlayingMatch = playingMatchExists;
        const playingPointExists = playingMatchExists.pointList.find(
          (p) => p.pointId === newState.currentlyPlayingPoint?.pointId
        );
        if (!playingPointExists) {
          newState.currentlyPlayingPoint = playingMatchExists.pointList[0];
          newState.currentlyPlayingShot =
            playingMatchExists.pointList[0].shots[0];
        } else {
          newState.currentlyPlayingPoint = playingPointExists;
          const playingShotExists = playingPointExists.shots.find(
            (s) => s.shotId === newState.currentlyPlayingShot?.shotId
          );
          if (!playingShotExists) {
            newState.currentlyPlayingShot =
              newState.currentlyPlayingPoint.shots[0];
          } else {
            newState.currentlyPlayingShot = playingShotExists;
          }
        }
      }
      return newState;
    },
    setIsPlaying(state, action) {
      state.isPlaying = action.payload;
    },
    setNavigateToPoint(state, action) {
      state.navigateToPoint = action.payload;
    },
    setShotsCount(state, action) {
      const prev = Number(action.payload.prevShotsCount);
      const next = Number(action.payload.nextShotsCount);
      if (!state.matchesPointList) {
        return {
          ...state,
          prevShotsCount: prev,
          nextShotsCount: next,
        };
      }
      const filteredMatches = getFilteredMatches(
        state.matchesPointList,
        prev,
        next
      );
      const currentlyPlayingMatch = state.currentlyPlayingMatch
        ? filteredMatches.find(
          (m) => m.matchId === state.currentlyPlayingMatch.matchId
        )
        : null;
      const currentlyPlayingPoint = state.currentlyPlayingPoint
        ? currentlyPlayingMatch?.pointList.find(
          (p) => p.pointId === state.currentlyPlayingPoint.pointId
        )
        : null;
      return {
        ...state,
        filteredMatches: filteredMatches,
        currentlyPlayingMatch: currentlyPlayingMatch,
        currentlyPlayingPoint: currentlyPlayingPoint,
        currentlyPlayingShot: currentlyPlayingPoint?.shots[0],
        prevShotsCount: prev,
        nextShotsCount: next,
      };
    },
    setCurrentlyPlayingMatch(state, action) {
      const matchId = action.payload;
      const match = state.filteredMatches.find((m) => m.matchId === matchId);
      state.currentlyPlayingMatch = match;
      state.currentlyPlayingPoint = match.pointList[0];
      state.currentlyPlayingShot = match.pointList[0].shots[0];
      state.isPlaying = true;
    },
    setCurrentlyPlayingPoint(state, action) {
      const pointId = action.payload;
      const match = state.filteredMatches.find((m) =>
        m.pointList.find((p) => p.pointId === pointId)
      );
      const point = match.pointList.find((p) => p.pointId === pointId);

      state.currentlyPlayingMatch = match;
      state.currentlyPlayingPoint = point;
      state.currentlyPlayingShot = point.shots[0];
      state.isPlaying = true;
    },
    setSelectedPointsIds(state, action) {
      state.selectedPointsIds = action.payload;
    },
    playNextShot(state, action) {
      const currentlyPlayingPoint = state.currentlyPlayingPoint;
      const currentlyPlayingShotIndex = currentlyPlayingPoint.shots.findIndex(
        (s) => s.shotId === state.currentlyPlayingShot.shotId
      );
      const nextShotIndex = currentlyPlayingShotIndex + 1;
      if (nextShotIndex < currentlyPlayingPoint.shots.length) {
        // there is a next shot, play it
        state.currentlyPlayingShot = currentlyPlayingPoint.shots[nextShotIndex];
      } else {
        // there is not a next shot so try playing the next point
        const currentlyPlayingMatch = state.currentlyPlayingMatch;

        const currentplyPlayingPointIndex =
          currentlyPlayingMatch.pointList.findIndex(
            (p) => p.pointId === currentlyPlayingPoint.pointId
          );
        const nextPointIndex = currentplyPlayingPointIndex + 1;
        if (nextPointIndex < currentlyPlayingMatch.pointList.length) {
          // there is a next point so play it
          const nextPoint = currentlyPlayingMatch.pointList[nextPointIndex];
          state.currentlyPlayingPoint = nextPoint;
          state.currentlyPlayingShot = nextPoint.shots[0];
        } else {
          // there is not a next point , so try playing next match
          const selectedMatchList = state.filteredMatches;
          const currentlyPlayingMatchIndex = selectedMatchList.findIndex(
            (m) => m.matchId === currentlyPlayingMatch.matchId
          );
          const nextMatchIndex = currentlyPlayingMatchIndex + 1;
          if (nextMatchIndex < selectedMatchList.length) {
            //there is a next match, play it
            const nextMatch = selectedMatchList[nextMatchIndex];
            state.currentlyPlayingMatch = nextMatch;
            state.currentlyPlayingPoint = nextMatch.pointList[0];
            state.currentlyPlayingShot = nextMatch.pointList[0].shots[0];
          } else {
            // there is not next match
            return {
              ...state,
              currentlyPlayingPoint: null,
              currentlyPlayingShot: null,
              isPlaying: false,
            };
          }
        }
      }
    },
  },
});

export const getFilteredMatches = (matches, prevShotsCount, nextShotsCount) => {
  return matches
    .filter((match) =>
      match.pointList.some(
        (point) => point.isSelected && point.shots.some((s) => s.isSelected)
      )
    )
    .map((match) => ({
      ...match,
      pointList: match.pointList
        .filter(
          (point) => point.isSelected && point.shots.some((s) => s.isSelected)
        )
        .map((point) => {
          const selectedShotIndices = point.shots
            .filter((s) => s.isSelected)
            .map((s) => point.shots.indexOf(s));
          return {
            ...point,
            shots: point.shots.filter(
              (s, index) =>
                s.isSelected ||
                selectedShotIndices.some(
                  (selectedIndex) =>
                    index >= selectedIndex - prevShotsCount &&
                    index <= selectedIndex + nextShotsCount
                )
            ),
          };
        }),
    }));
};

export const {
  setMatchList,
  setCurrentlyPlayingMatch,
  setCurrentlyPlayingPoint,
  setIsPlaying,
  setShotsCount,
  setNavigateToPoint,
  setSelectedPointsIds,
  playNextShot,
} = videoPlayerSlice.actions;

export const selectMatchesPointList = (state) => state.shotsVideoPlayer.matchesPointList;
export const selectFilteredMatches = (state) => state.shotsVideoPlayer.filteredMatches;
export const selectIsPlaying = (state) => state.shotsVideoPlayer.isPlaying;
export const selectCurrentlyPlayingMatch = (state) => state.shotsVideoPlayer.currentlyPlayingMatch;
export const selectCurrentlyPlayingPoint = (state) => state.shotsVideoPlayer.currentlyPlayingPoint;
export const selectCurrentlyPlayingShot = (state) => state.shotsVideoPlayer.currentlyPlayingShot;
export const selectPrevShotsCount = (state) => state.shotsVideoPlayer.prevShotsCount;
export const selectNextShotsCount = (state) => state.shotsVideoPlayer.nextShotsCount;
export const selectNavigateToPoint = (state) => state.shotsVideoPlayer.navigateToPoint;
export const selectSelectedPointsIds = (state) => state.shotsVideoPlayer.selectedPointsIds;
export default videoPlayerSlice.reducer;
